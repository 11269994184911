import {
    Control,
    FieldErrors,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
} from 'react-hook-form';
import { AppointmentMethod } from '~/schemaTypes';

export type VirtualCareSurveyInput = {
    firstName: string;
    lastName: string;
    birthDate: Date | string;
    email: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    phoneNumber: string;
    sex: string;
    insuranceId: string;
    insuranceGroup: string;
    patientRelationshipToInsured: string;
    insuredFirstName: string;
    insuredLastName: string;
    insuredAddressLine1: string;
    insuredAddressLine2: string;
    insuredCity: string;
    insuredState: string;
    insuredZipCode: string;
    subscriberSex: string;
    subscriberBirthDate: Date | string;
    insuredAddressSameAsPatient: boolean;
    appointmentMethod?: AppointmentMethod;
    babyMultiples?: boolean;
    babyArrived?: boolean;
    babyFirstName?: string;
    babyLastName?: string;
    babyBirthDate?: Date | string;
    babySex?: string;
    babyDueDate?: Date | string;
};

export const RelationshipToInsuredEnum = {
    SELF: 'self',
    SPOUSE: 'spouse',
    CHILD: 'child',
    OTHER: 'other',
} as const;

export const EMPTY_GENDER_VALUE = { id: '', label: '' };
export const inputGenders = [
    EMPTY_GENDER_VALUE,
    { id: 'female', label: 'Female' },
    { id: 'male', label: 'Male' },
    { id: 'non-binary', label: 'Non-Binary' },
    { id: 'other', label: 'Other' },
];

export const inputBabyGenders = [
    EMPTY_GENDER_VALUE,
    { id: 'male', label: 'Male' },
    { id: 'female', label: 'Female' },
    { id: 'dontKnow', label: "Don't know yet" },
];

export type StepsProps = {
    register: UseFormRegister<VirtualCareSurveyInput>;
    setValue: UseFormSetValue<VirtualCareSurveyInput>;
    getValues: UseFormGetValues<VirtualCareSurveyInput>;
    errors: FieldErrors<VirtualCareSurveyInput>;
    control: Control<VirtualCareSurveyInput>;
    classes: Record<string, string>;
    isMobileView: boolean;
};
