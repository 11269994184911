import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import { Add, ImageOutlined } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { ReactElement, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CIGNA_LACTATION_ORG_ID, PAGESIZE } from '~/constants';
import { displayDateLocale } from '~/helpers/dateHelpers';
import tableIcons from '~/helpers/tableIcons';
import { useQueryParams, useUser, useUserPermissions } from '~/hooks';
import {
    FetchUsersForStaffPageQueryDocument,
    FetchUsersForStaffPageQueryQuery,
    useDeleteUserForStaffPageMutation,
    useFetchUsersForStaffPageQueryQuery,
} from '~/schemaTypes';
import { AdminUserEnum } from '~/selectors';
import { TriggerGlobalConfirm, TriggerGlobalImagePreview } from '~/state';
import StaffModal from './StaffModal/StaffModal';
import VCAffiliateUserModal from './VCAffiliateUsersModal/VCAffiliateUsersModal';

type Users = FetchUsersForStaffPageQueryQuery['getUsersByOrg'][0];

export default function Staff(): ReactElement {
    const [query] = useQueryParams();
    const [search, setSearch] = useState<string>(query.get('_search') ?? '');
    const isAffiliateUsers = query.get('affiliate-users-only');
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const [showStaffModal, setShowStaffModal] = useState(false);
    const [editStaffId, setEditStaffId] = useState<string>('');
    const { data: userData } = useUser();

    const currentOrgId = isAffiliateUsers
        ? CIGNA_LACTATION_ORG_ID
        : userData?.currentUser?.currentOrg.id;

    const { data: staffData, loading: staffLoading } = useFetchUsersForStaffPageQueryQuery({
        variables: {
            input: {
                orgId: currentOrgId,
            },
        },
    });

    const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserForStaffPageMutation({
        update: (cache, res) => {
            const staffQuery = cache.readQuery<FetchUsersForStaffPageQueryQuery>({
                query: FetchUsersForStaffPageQueryDocument,
                variables: {
                    input: {
                        orgId: currentOrgId,
                    },
                },
            });
            const filteredUsers =
                staffQuery?.getUsersByOrg?.filter(user => user?.id !== res.data?.deleteUser?.id) ??
                [];
            if (staffQuery?.getUsersByOrg) {
                cache.writeQuery<FetchUsersForStaffPageQueryQuery>({
                    query: FetchUsersForStaffPageQueryDocument,
                    data: { getUsersByOrg: filteredUsers },
                    variables: {
                        input: {
                            orgId: currentOrgId,
                        },
                    },
                });
            }
        },
    });

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div data-test={AdminUserEnum.CONTAINER} ref={tableRef}>
            <MaterialTable<Users>
                isLoading={staffLoading || deleteUserLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actions={[
                    {
                        onClick: () => {
                            setEditStaffId('');
                            setShowStaffModal(true);
                        },
                        hidden: !pagePermissions?.Staff.Edit,
                        icon: () => <Add />,
                        tooltip: isAffiliateUsers ? 'Add Affiliate User' : 'Add Admin User',
                        isFreeAction: true,
                    },
                    ({ isActive }) => ({
                        onClick: (_e, user: any) => {
                            setEditStaffId(user.id);
                            setShowStaffModal(true);
                        },
                        disabled: !pagePermissions?.Staff.Edit || !isActive,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: isAffiliateUsers ? 'Edit Affiliate User' : 'Edit Admin User',
                    }),
                    ({ isActive }) => ({
                        onClick: (_e, { id }: any) => {
                            TriggerGlobalConfirm({
                                message: isAffiliateUsers
                                    ? 'Do you want to delete this Affiliate User?'
                                    : 'Do you want to delete this Admin User?',
                                callback: () => {
                                    deleteUser({
                                        variables: {
                                            input: {
                                                id,
                                            },
                                        },
                                    });
                                },
                            });
                        },
                        disabled: !isActive,
                        hidden: !pagePermissions?.Staff.Delete,
                        tooltip: isAffiliateUsers ? 'Delete Affiliate User' : 'Delete Admin User',
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                    }),
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        defaultSort: 'asc',
                        render: ({ name }) => <Typography data-test={name}>{name}</Typography>,
                    },
                    { title: 'Email', field: 'email' },
                    {
                        title: 'Active Status',
                        field: 'isActive',
                        render: ({ isActive }) =>
                            isActive ? (
                                <Typography>Active</Typography>
                            ) : (
                                <Typography color="error">Not Active</Typography>
                            ),
                    },
                    { title: 'Title', field: 'title' },
                    {
                        title: 'Last Login',
                        field: 'lastLogin',
                        align: 'center',
                        sorting: false,
                        render: ({ lastLogin }) => {
                            if (lastLogin) {
                                return displayDateLocale({ isoDateStr: lastLogin });
                            }
                            return '';
                        },
                    },
                    { title: 'Root Org', field: 'rootOrg.brandingName' },
                    { title: 'SMS Number', field: 'smsNumber' },
                    {
                        title: 'Role',
                        render: ({ role }) => (
                            <Typography>{role?.name ?? 'Deleted Role'}</Typography>
                        ),
                    },
                    {
                        title: 'Photo',
                        field: 'photo',
                        align: 'center',
                        sorting: false,
                        render: ({ photo }) => {
                            if (photo) {
                                return (
                                    <Tooltip title={photo ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                TriggerGlobalImagePreview({ imageSrc: photo });
                                            }}
                                            size="large"
                                        >
                                            <ImageOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                ]}
                data={_.cloneDeep(staffData?.getUsersByOrg ?? [])}
                title={isAffiliateUsers ? 'Affiliate Users' : 'Admin Users'}
                localization={{ header: { actions: '' } }}
                onSearchChange={searchText => {
                    setSearch(searchText);
                    history(
                        isAffiliateUsers
                            ? `/app-config/staff?affiliate-users-only=true${
                                  searchText && `&_search=${searchText}`
                              }`
                            : `/app-config/staff${searchText && `?_search=${searchText}`}`,
                    );
                }}
                options={{
                    searchText: search ?? '',
                    pageSize: rowsPerPage,
                }}
            />
            <Dialog
                scroll="paper"
                open={showStaffModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                {isAffiliateUsers ? (
                    <VCAffiliateUserModal
                        setOpen={setShowStaffModal}
                        setEditStaffId={setEditStaffId}
                        id={editStaffId}
                    />
                ) : (
                    <StaffModal
                        setOpen={setShowStaffModal}
                        setEditStaffId={setEditStaffId}
                        id={editStaffId}
                    />
                )}
            </Dialog>
        </div>
    );
}
