import { ClientSelectionType } from '~/enums/enums';
import { LocalizedString } from '../types/LocalizedString';
import { MessageCenterTemplateFormInput } from '../types/MessageCenterTemplateFormInput';
import { MessageCenterTemplates, MessageCenterTemplatesContent } from '../types/Schema';

const defaultLocalizedString = (): LocalizedString => ({ en: '', es: '' });

export const defaultFormValues = (): Partial<MessageCenterTemplateFormInput> => ({
    title: '',
    content: defaultLocalizedString() as MessageCenterTemplatesContent,
    clientSelectionType: ClientSelectionType.IncludeAllApps,
    includeApps: [] as string[],
    excludeApps: [] as string[],
});

export const toFormValuesFromTemplate = (): MessageCenterTemplateFormInput => ({
    title: '',
    content: defaultLocalizedString() as MessageCenterTemplatesContent,
    clientSelectionType: ClientSelectionType.IncludeAllApps,
    includeApps: [],
    excludeApps: [],
});

const toClientSelectionType = ({
    excludeApps,
    includeApps,
}: MessageCenterTemplates): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

export const toFormValuesFromMessageTemplate = (
    template: MessageCenterTemplates,
): MessageCenterTemplateFormInput => {
    return {
        title: template?.title ?? '',
        content: {
            en: template?.content?.en ?? '',
            es: template?.content?.es ?? '',
        },
        clientSelectionType: toClientSelectionType(template),
        includeApps: (template.includeApps as string[]) ?? [],
        excludeApps: (template.excludeApps as string[]) ?? [],
    };
};
