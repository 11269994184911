import { ClientSelectionType } from '~/enums/enums';
import { toBase64 } from '~/helpers/base64Helper';
import { LocalizedString } from '../types/LocalizedString';
import { MessageCenterTemplateFormInput } from '../types/MessageCenterTemplateFormInput';

type MessageCenterTemplateFormType = {
    title: string;
    content: LocalizedString;
    includeApps: string[];
    excludeApps: string[];
};

const toIncludeOrExclude = ({
    clientSelectionType,
    includeApps,
    excludeApps,
}: MessageCenterTemplateFormInput) => {
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
        default:
            return {
                includeApps: [],
                excludeApps: [],
            };
    }
};

export const formValuesToCreateOrUpdateInput = (
    form: MessageCenterTemplateFormInput,
): MessageCenterTemplateFormType => {
    const { content, title } = form;

    content.en = toBase64(form.content.en) || '';
    content.es = form.content.es ? toBase64(form.content.es) : '';

    return {
        title,
        content,
        ...toIncludeOrExclude(form),
    };
};
