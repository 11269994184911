import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable, { MTableAction, OrderByCollection } from '@material-table/core';

import {
    AffiliateClaim,
    AffiliateClaimStatus,
    AlertSeverity,
    OrderByDirectionEnum,
    useFetchAffiliateClaimCsvLazyQuery,
    useFetchAffiliateClaimSubmissionsQuery,
} from '~/schemaTypes';
import {
    DEFAULT_ORDER_DIRECTION,
    OrderByFields,
    OrderDirectionMap,
    ROWS_PER_PAGE,
} from '~/views/ConfigDashboard/VCAffiliateClaimSubmissions/constants';
import {
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Link,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import CloseIcon from '@mui/icons-material/Close';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import { useStyles } from '~/views/ConfigDashboard/VCAffiliateClaimSubmissions/styles';
import useUserPermissions from '~/hooks/useUserPermissions';
import { TriggerGlobalAlert } from '~/state';

type ErrorTextWrapperProps = {
    text: string;
    isAffiliateView: boolean;
};

const errorTextNoMatch = 'not able to match the following patients';

const ErrorTextContainer: React.FC<ErrorTextWrapperProps> = props => {
    const { isAffiliateView, text } = props;
    if (!isAffiliateView) {
        return <Box>{text}</Box>;
    }
    if (text.toLowerCase().includes(errorTextNoMatch)) {
        return (
            <Box>
                <Typography>{text}</Typography>
                <Typography>
                    <Link
                        href="/affiliate-care-portal/patients"
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                    >
                        Compare to Patient List
                    </Link>
                </Typography>
            </Box>
        );
    }
    return <Box>{text}</Box>;
};

export const VCAffiliateClaimSubmissions = () => {
    const { classes } = useStyles();
    const { isAffiliateView, userAffiliateId } = useAffiliateCareView();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [dialogContentText, setDialogContentText] = useState('');
    const [searchStr, setSearchStr] = useState<string>('');
    const [statusFilter, setStatusFilter] = useState(AffiliateClaimStatus.Submitted);
    const handleCloseModal = useCallback(() => {
        setIsErrorDialogOpen(() => false);
        setDialogContentText(() => '');
    }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
    const [orderDirection, setOrderDirection] =
        useState<OrderByDirectionEnum>(DEFAULT_ORDER_DIRECTION);

    const fetchVariables = useMemo(() => {
        return {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: OrderByFields.dateTime,
                    order: orderDirection,
                },
                filter: {
                    fields: {
                        ...(isAffiliateView && { status: statusFilter }),
                        ...(userAffiliateId && { affiliateId: userAffiliateId }),
                        ...(searchStr && { internalName: searchStr }),
                    },
                },
            },
        };
    }, [
        orderDirection,
        page,
        rowsPerPage,
        userAffiliateId,
        searchStr,
        statusFilter,
        isAffiliateView,
    ]);

    const onChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
            setPage(() => pageNumber);
        },
        [],
    );

    const onChangeRowsPerPage = useCallback((pageSize: number) => {
        setRowsPerPage(() => pageSize);
    }, []);

    const onOrderChange = useCallback(
        (orderByCollection: OrderByCollection[]) => {
            const orderOptions = orderByCollection[0];
            if (orderOptions) {
                const { orderDirection: direction } = orderOptions;
                if (direction.toUpperCase() !== orderDirection) {
                    setOrderDirection(OrderDirectionMap[direction]);
                }
            }
        },
        [orderDirection],
    );

    const { loading, data } = useFetchAffiliateClaimSubmissionsQuery({
        variables: fetchVariables,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const [downloadCsv] = useFetchAffiliateClaimCsvLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const onSearchChange = useCallback((searchStr: string) => setSearchStr(searchStr), []);

    const title = isAffiliateView ? 'Batch Claims' : 'VC Affiliate Claim Submissions';

    return (
        <>
            <MaterialTable
                title={title}
                columns={[
                    {
                        title: 'DateTime',
                        field: 'dateTime',
                        sorting: true,
                        render: ({ dateTime }) => (
                            <Typography data-test={dateTime}>
                                {new Intl.DateTimeFormat('en-US', {
                                    timeStyle: 'medium',
                                    dateStyle: 'short',
                                }).format(new Date(dateTime))}
                            </Typography>
                        ),
                        customFilterAndSearch: () => true,
                    },
                    {
                        title: 'Affiliate',
                        field: 'affiliateId',
                        render: ({ affiliate }) => (
                            <Link href={`/app-config/vcaffiliates/${affiliate?.id}`}>
                                {affiliate?.internalName}
                            </Link>
                        ),
                        hidden: isAffiliateView,
                        searchable: false,
                    },
                    {
                        title: 'ID',
                        field: 'id',
                        render: ({ id }) => <Typography data-test={id}>{id}</Typography>,
                        searchable: false,
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        render: ({ status, errorText }) => (
                            <Box>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    data-test={status}
                                    className={`${classes.statusTextColor} ${status}`}
                                >
                                    {status}
                                </Typography>
                                {status === AffiliateClaimStatus.Error && (
                                    <Tooltip title="Show errors">
                                        <IconButton
                                            onClick={() => {
                                                setIsErrorDialogOpen(() => true);
                                                setDialogContentText(() => errorText ?? '');
                                            }}
                                            size="small"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        ),
                        searchable: false,
                    },
                ]}
                actions={[
                    {
                        onClick: async (_, data: AffiliateClaim) => {
                            if (!data.location) {
                                TriggerGlobalAlert({
                                    message: 'File Not Found',
                                    severity: AlertSeverity.Warning,
                                });
                                return;
                            }
                            const { data: acData } = await downloadCsv({
                                variables: {
                                    input: {
                                        key: data.fileName,
                                    },
                                },
                            });
                            if (
                                acData &&
                                acData.getAffiliateClaimCSVByKey &&
                                acData?.getAffiliateClaimCSVByKey?.data
                            ) {
                                const blob = new Blob([
                                    new Uint8Array(
                                        acData.getAffiliateClaimCSVByKey.data as unknown as Buffer,
                                    ).buffer,
                                ]);

                                const link = document.createElement('a');

                                link.setAttribute('href', window.URL.createObjectURL(blob));
                                link.setAttribute('download', data.fileName || '');
                                document.body.appendChild(link);
                                link.click();
                                link.remove();
                            }
                        },
                        icon: () => <FontAwesomeIcon icon={faFileCsv} />,
                        tooltip: 'Download CSV',
                        position: 'row',
                    },
                    {
                        isFreeAction: true,
                        icon: () => (
                            <FormControlLabel
                                label="Show Submission Errors"
                                control={
                                    <Checkbox
                                        checked={statusFilter === AffiliateClaimStatus.Error}
                                        onChange={event =>
                                            setStatusFilter(
                                                event.target.checked
                                                    ? AffiliateClaimStatus.Error
                                                    : AffiliateClaimStatus.Submitted,
                                            )
                                        }
                                    />
                                }
                            />
                        ),
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => {},
                        hidden: Boolean(
                            !isAffiliateView ||
                                !pagePermissions?.AffiliateCareClaimsSubmission.Edit,
                        ),
                    },
                    {
                        isFreeAction: true,
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => {},
                        hidden: Boolean(
                            !isAffiliateView ||
                                !pagePermissions?.AffiliateCareClaimsSubmission.Edit,
                        ),
                        icon: () => (
                            <Button
                                variant="contained"
                                onClick={() =>
                                    history('/affiliate-care-portal/claims/build-claims')
                                }
                                size="small"
                            >
                                Submit Claim
                            </Button>
                        ),
                    },
                ]}
                data={data?.paginatedAffiliateClaim?.results ?? []}
                options={{
                    search: !isAffiliateView,
                    paging: true,
                    pageSize: ROWS_PER_PAGE,
                    pageSizeOptions: [ROWS_PER_PAGE, 20, 50],
                    actionsColumnIndex: -1,
                    debounceInterval: 500,
                }}
                localization={{ header: { actions: '' } }}
                isLoading={loading}
                onRowsPerPageChange={onChangeRowsPerPage}
                onOrderCollectionChange={onOrderChange}
                onSearchChange={onSearchChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={data?.paginatedAffiliateClaim?.total ?? 0}
                            page={page}
                            onPageChange={onChangePage}
                        />
                    ),
                    Action: props => {
                        const {
                            action: { isFreeAction, icon, hidden },
                        } = props;
                        if (isFreeAction && !hidden) {
                            return icon();
                        }
                        return <MTableAction {...props} />;
                    },
                }}
            />
            <Dialog open={isErrorDialogOpen}>
                <DialogTitle textAlign="right">
                    <IconButton onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Container fixed className={classes.dialogContent}>
                        <ErrorTextContainer
                            text={dialogContentText}
                            isAffiliateView={isAffiliateView}
                        />
                    </Container>
                </DialogContent>
            </Dialog>
        </>
    );
};
