import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    from,
    split,
} from '@apollo/client';
import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context';
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';
// eslint-disable-next-line import/no-extraneous-dependencies
import { WebSocketLink } from '@apollo/client/link/ws';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMainDefinition } from '@apollo/client/utilities';
import { logInfo, logWarn } from '~/helpers';
import { AUTH_TOKEN_KEY } from '~/models/auth';
import {
    AccessToken,
    ApolloCache,
    AuthTokens,
    IdToken,
    PortalVersionMismatchLastCheck,
    TriggerGlobalAlert,
    TriggerGlobalConfirm,
} from '~/state';
import { JWT, getCurrentUser } from '@aws-amplify/auth';
import { signOut } from '@aws-amplify/auth/cognito';
import { jwtDecode } from 'jwt-decode';
import LocalSchema from './state/localSchema';
import { AlertSeverity } from './schemaTypes';
import { refreshCognitoTokenAndInitSignOutWorker } from './utils/refreshCognitoAccessToken';

// let logoutWorker: SingOutEventEmitter | null = null;
type ApolloClientProps = {
    children: React.ReactNode;
};

const wsLink = new WebSocketLink({
    uri: window.location.host.startsWith('localhost')
        ? 'ws://localhost:4444/api/portal/subscriptions'
        : `${
              (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host
          }/api/portal/subscriptions`,
    options: {
        reconnect: true,
        connectionParams: () =>
            localStorage.getItem(AuthTokens.WfhAuthToken)
                ? { 'wfh-auth-token': localStorage.getItem(AuthTokens.WfhAuthToken) }
                : {
                      authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
                      id: `Bearer ${localStorage.getItem('IdToken')}`,
                  },
    },
});

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL || '/api/portal/graphql',
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
);

const errorHandlerLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        const accessToken = AccessToken();
        // eslint-disable-next-line no-console
        console.log('errorHandlerLink graphQLErrors', graphQLErrors);
        const { pathname } = window.location;
        graphQLErrors.forEach(({ message, path }) => {
            // /patient-invite pages and /virtual-care are public and dont need user info
            if (
                path?.includes('roleOfCurrentUser') &&
                !accessToken &&
                (pathname === '/patient-invite' ||
                    pathname.startsWith('/virtual-care') ||
                    (pathname.startsWith('/affiliate-care') &&
                        !pathname.startsWith('/affiliate-care-portal')))
            ) {
                return;
            }
            if (
                (path?.includes('roleOfCurrentUser') && !accessToken) ||
                (path?.includes('currentUser') && !accessToken)
            ) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Warning,
                    message: 'Your session expired, please login again.',
                });
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message,
                });
            }
        });
    }
    if (networkError) {
        // eslint-disable-next-line no-console
        console.log('errorHandlerLink networkError', networkError);
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: networkError.message,
        });
    }
});

const responseMiddlewareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        try {
            // Check Portal-UI Version
            if (
                !PortalVersionMismatchLastCheck() ||
                (PortalVersionMismatchLastCheck()?.valueOf() &&
                    Date.now() - (PortalVersionMismatchLastCheck()?.valueOf() as number) >
                        5 * 60 * 1000)
            ) {
                logInfo({ message: `Checking Portal-UI Version` });
                PortalVersionMismatchLastCheck(new Date());
                const context = operation.getContext();
                const portalVersionHeader = context.response.headers.get('X-Portal-UI-Version');
                if (!process.env.REACT_APP_VERSION) {
                    logWarn({
                        message: `No Local Version Detected: process.env.REACT_APP_VERSION === ${process.env.REACT_APP_VERSION}`,
                    });
                }
                logInfo({ message: `Current Local Version: ${process.env.REACT_APP_VERSION}` });
                if (!portalVersionHeader) {
                    logWarn({
                        message: `No Remote Version Detected: Header['X-Portal-UI-Version' === ${portalVersionHeader}`,
                    });
                }
                logInfo({ message: `Current Remote Version: ${portalVersionHeader}` });
                if (portalVersionHeader && process.env.REACT_APP_VERSION) {
                    if (portalVersionHeader !== process.env.REACT_APP_VERSION) {
                        logInfo({
                            message: `Portal Version mismatch detected, prompting Page Refresh`,
                        });
                        TriggerGlobalConfirm({
                            message: `New version of Portal available, please refresh your page by clicking confirm.`,
                            description:
                                'If you are in the middle of something feel free to click cancel and refresh the page manually after you finish your work.',
                            callback: () => {
                                window.location.reload();
                            },
                        });
                    }
                }
            }

            // Check for mutation errors
            const data = response?.data;
            if (data) {
                const keys = Object.keys(data);
                keys.forEach(key => {
                    const res = data[key];
                    if (res) {
                        const { success, message } = res;
                        if (success) {
                            TriggerGlobalAlert({
                                severity: AlertSeverity.Success,
                                message:
                                    message ??
                                    `${operation.operationName
                                        .replace(/([A-Z])/g, ' $1')
                                        .trim()}: Succeeded`,
                            });
                        }
                        if (success === false) {
                            TriggerGlobalAlert({
                                severity: AlertSeverity.Error,
                                message:
                                    message ??
                                    `${operation.operationName
                                        .replace(/([A-Z])/g, ' $1')
                                        .trim()}: Failed`,
                            });
                        }
                    }
                });
            }
        } catch (_error) {
            return response;
        }
        return response;
    });
});

const authLink = setContext(async (_op, { headers }) => {
    const idToken = IdToken();
    const accessToken = AccessToken();
    let newAccessToken: JWT | undefined;
    let newIdToken: JWT | undefined;
    const wfhAuthToken = localStorage.getItem(AuthTokens.WfhAuthToken);
    const { operationName } = _op;
    // FetchChatRoomsForMessageCenter is not considered user activity, is called in background for unread messages.
    const isActiveOperation = operationName !== 'FetchChatRoomsForMessageCenter';
    try {
        if (idToken && accessToken && !wfhAuthToken) {
            // This will throw error if tokens are no longer valid
            await getCurrentUser();
            const expirationTimestamp = accessToken.getExpiration();
            const tokensExpired = expirationTimestamp * 1000 - new Date().valueOf() < 0;

            if (tokensExpired) {
                // token is expired, sign out the user
                await signOut();
            }

            const tokensOlderThan1Min =
                new Date().valueOf() - accessToken.decodePayload().iat * 1000 > 60000;

            if (tokensOlderThan1Min && isActiveOperation) {
                const tokens = await refreshCognitoTokenAndInitSignOutWorker();
                if (tokens === null) {
                    throw new Error('No tokens found');
                }
                newAccessToken = tokens.accessToken;
                newIdToken = tokens.idToken;
            }
        }
        if (wfhAuthToken) {
            const decoded = jwtDecode(wfhAuthToken);
            const exp = decoded?.exp;
            if (exp && Date.now() >= exp * 1000) {
                throw new Error('WfhAuthToken expired');
            }
        }
    } catch (error) {
        TriggerGlobalAlert({
            message: 'Your session expired, please login again.',
            severity: AlertSeverity.Warning,
        });
        signOut();
        AccessToken(null);
        localStorage.setItem(AuthTokens.AccessToken, '');
        IdToken(null);
        localStorage.setItem(AuthTokens.IdToken, '');
        localStorage.removeItem(AUTH_TOKEN_KEY);
        localStorage.setItem(AuthTokens.WfhAuthToken, '');
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        client.clearStore();
        // inactive login modal windows for queries not considered as activity
        if (isActiveOperation) {
            // eslint-disable-next-line no-self-assign
            window.location = window.location;
        }
        const { authorization, id, 'wfh-auth-token': wfhAuthToken, ...headersToReturn } = headers;
        return {
            headers: {
                ...headersToReturn,
                'apollographql-client-name': 'Portal-UI',
                'apollographql-client-version': '1.0.0',
            },
        };
    }
    let authorization = '';
    let id = '';
    if (newAccessToken) {
        authorization = `Bearer ${newAccessToken.toString()}`;
    } else if (accessToken) {
        authorization = `Bearer ${accessToken.getJwtToken()}`;
    }
    if (newIdToken) {
        id = `Bearer ${newIdToken.toString()}`;
    } else if (idToken) {
        id = `Bearer ${idToken.getJwtToken()}`;
    }
    let headersToReturn = {
        ...headers,
        'apollographql-client-name': 'Portal-UI',
        'apollographql-client-version': '1.0.0',
    };
    if (wfhAuthToken) {
        headersToReturn = { 'wfh-auth-token': wfhAuthToken, ...headersToReturn };
    } else {
        headersToReturn = { authorization, id, ...headersToReturn };
    }
    return {
        headers: headersToReturn,
    };
});

let sessionId = crypto.randomUUID();
const sessionLink = new ApolloLink((operation, forward) => {
    if (sessionId != null) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'wfh-session-id': sessionId,
            },
        }));
    }
    return forward(operation).map(response => {
        const responseSessionId = operation.getContext().response?.headers.get('wfh-session-id');
        if (responseSessionId != null) {
            sessionId = responseSessionId;
        }
        return response;
    });
});

export const client = new ApolloClient({
    link: from([responseMiddlewareLink, sessionLink, authLink, errorHandlerLink, splitLink]),
    cache: ApolloCache,
    typeDefs: LocalSchema,
});

export const ApolloClientWrapper: React.FC<ApolloClientProps> = ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
