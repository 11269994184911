import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText, Grid, MenuItem, Select, Typography } from '@mui/material';
import { EventAvailable, HomeOutlined } from '@mui/icons-material';
import { AppointmentMethod as AppointmentMethodEnum } from '~/schemaTypes';
import { StepsProps } from '../types';
import FooterDivider from '../components/FooterDivider';
import ButtonContainer from '../components/ButtonContainer';
import SupportBlock from '../components/SupportBlock';

type AppointmentMethodProps = {
    stepHandler: () => void;
    stepLoading: boolean;
    backHandler: () => void;
    onlyAtHomeOption: boolean;
    type?: AppointmentMethodEnum;
} & StepsProps;

type PlaceholderProps = {
    type?: AppointmentMethodEnum | '';
};

const IconByType = {
    [AppointmentMethodEnum.AtHome]: <HomeOutlined style={{ fontSize: '50px' }} />,
    [AppointmentMethodEnum.OnDemand]: <img src="logo192.png" alt="Method logo" height="50px" />,
    [AppointmentMethodEnum.Virtual]: <EventAvailable style={{ fontSize: '50px' }} />,
    [AppointmentMethodEnum.Scheduled]: <EventAvailable style={{ fontSize: '50px' }} />,
};

const TextByType = {
    [AppointmentMethodEnum.AtHome]:
        'By choosing an in-person consult, we pair you with one of our preferred IBCLC consultants closest to you. In-person visits are best for hands-on assessments and situations that require physical examination or intervention. Wait times can vary, but you will generally be seen within 1-3 days.',
    [AppointmentMethodEnum.OnDemand]:
        'Choosing On-Demand will route you to the download our partner app Pacify to request a virtual appointment. You will be placed in a queue until the next lactation consultant is available.',
    [AppointmentMethodEnum.Virtual]:
        'Virtual visits are best for prenatal appointments, general advice, and situations where immediate guidance is needed. We offer same- or next-day appointments and can always refer you to one of our preferred IBCLC providers if an in-person consult is required or requested.',
    [AppointmentMethodEnum.Scheduled]:
        'Choosing Virtual will allow you to schedule a future virtual appointment with a lactation consultant.',
};

const SelectedPlaceholder: React.FC<PlaceholderProps> = React.memo(props => {
    const { type } = props;
    if (!type) {
        return null;
    }
    return (
        <Grid container flexDirection="column" justifyContent="center" padding="10px">
            <Grid item textAlign="center" height="60px">
                {IconByType[type]}
            </Grid>
            <Grid item>
                <Typography>{TextByType[type]}</Typography>
            </Grid>
        </Grid>
    );
});

const AppointmentMethod: React.FC<AppointmentMethodProps> = props => {
    const {
        stepLoading,
        errors,
        control,
        classes,
        stepHandler,
        isMobileView,
        backHandler,
        onlyAtHomeOption,
        type,
        setValue,
    } = props;
    React.useEffect(
        () => {
            if (onlyAtHomeOption) {
                setValue('appointmentMethod', AppointmentMethodEnum.AtHome);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onlyAtHomeOption],
    );
    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.subHeader} textAlign="center">
                    <Typography paragraph variant="h6">
                        Please select your preferred appointment method
                    </Typography>
                </Grid>
                <Grid item xs={12} alignItems="center">
                    <Grid container justifyContent="space-around" rowSpacing={2}>
                        <Grid item xs={10}>
                            <Controller
                                control={control}
                                name="appointmentMethod"
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth error={!!errors.appointmentMethod}>
                                        <Select
                                            fullWidth
                                            value={value || ''}
                                            onChange={e => onChange(e.target.value)}
                                            error={!!errors.appointmentMethod}
                                            displayEmpty
                                        >
                                            <MenuItem key="empty" value="">
                                                --Select--
                                            </MenuItem>
                                            <MenuItem
                                                key={AppointmentMethodEnum.AtHome}
                                                value={AppointmentMethodEnum.AtHome}
                                            >
                                                In-Person Consult
                                            </MenuItem>
                                            <MenuItem
                                                key={AppointmentMethodEnum.Virtual}
                                                value={AppointmentMethodEnum.Virtual}
                                            >
                                                Virtual Consult
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText>
                                            {errors.appointmentMethod?.message}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={10} minHeight={200}>
                            <SelectedPlaceholder type={type} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SupportBlock isMobileView={isMobileView} align="center" />
            <FooterDivider isMobileView={isMobileView} />
            <ButtonContainer
                nextButton={{
                    handler: stepHandler,
                    label:
                        type === AppointmentMethodEnum.OnDemand
                            ? 'Continue to App Store'
                            : 'Continue',
                    disabled: stepLoading,
                    loading: stepLoading,
                }}
                prevButton={{
                    handler: backHandler,
                    label: 'Back',
                }}
            />
        </>
    );
};

export default AppointmentMethod;
