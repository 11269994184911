import { faClone, faEye, faPenToSquare, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Dialog, IconButton, Tooltip, TablePagination } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUser, useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    Article,
    ArticleListDocument,
    ArticleListFilterInput,
    ArticleListQuery,
    ArticleListQueryVariables,
    BatchTaskStatus,
    BatchTaskType,
    useArticleListQuery,
    useArticleWhereUsedLazyQuery,
    useCloneArticleMutation,
    useCreateBatchTaskMutation,
    useDeleteArticleMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import CloneArticleModal from './components/CloneArticleModal';

type ArticlesList = NonNullable<ArticleListQuery['articlesV2']>['results'][0];

export interface CloneArticleModalParms {
    existingArticleId: string;
    submitHandler: (id: string, name: string) => void;
}

const useStyles = makeStyles()({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    paper: { minWidth: '400px' },
});

const Articles: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const { data: userData } = useUser();
    const history = useNavigate();
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [showCloneModal, setShowCloneModal] = useState(false);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [filter, setFilter] = useState<IFilterChange<ArticlesList> | null>(null);
    const [articleId, setArticleId] = useState<string>('');
    const [articleName, setArticleName] = useState<string>('');

    const articlesListQueryVariables: ArticleListQueryVariables = {
        input: {
            pagination: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            },
            filter: filter as ArticleListFilterInput | null,
        },
    };

    const { data: articleList, loading: articlesLoading } = useArticleListQuery({
        variables: articlesListQueryVariables,
    });

    const [createBatchTask] = useCreateBatchTaskMutation({});
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useArticleWhereUsedLazyQuery();

    const [deleteArticle, { loading: deleteArticleLoading }] = useDeleteArticleMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ArticleListDocument,
                variables: articlesListQueryVariables,
            },
        ],
    });

    const [cloneArticle, { loading: cloneArticleLoading }] = useCloneArticleMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ArticleListDocument,
                variables: articlesListQueryVariables,
            },
        ],
    });

    const handleDelete = () => {
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${articleName}'?`,
            callback: () => {
                deleteArticle({ variables: { input: articleId } });
            },
        });
        setShowUsageModal(false);
    };

    const handlePublishClick = () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to publish all Articles?`,
            callback: () => {
                createBatchTask({
                    variables: {
                        input: {
                            type: BatchTaskType.PublishArticles,
                            status: BatchTaskStatus.NotStarted,
                            addedBy: userData?.currentUser?.name ?? '',
                            priority: 5,
                            runAfter: new Date(),
                        },
                    },
                });
            },
        });
    };
    const handleShowUsage = (id: string) => {
        whereUsed({ variables: { articleId: id } });
        setArticleId(id);
        setShowUsageModal(true);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleCloneArticleSubmit = (id: string, name: string) => {
        cloneArticle({ variables: { input: { articleId: id, name } } });
        setShowCloneModal(false);
    };
    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Articles"
                icons={tableIcons}
                isLoading={articlesLoading || deleteArticleLoading || whereUsedLoading}
                data={articleList?.articlesV2.results ?? []}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={articleList?.articlesV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        onClick: event => {
                            event.preventDefault();
                        },
                        hidden: !pagePermissions?.Articles.Edit,
                        icon: () => (
                            <CustomFilter<ArticlesList>
                                setFilter={setFilter}
                                fields={['name', 'description', 'keywords']}
                                defaultField="name"
                            />
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: handlePublishClick,
                        hidden: !pagePermissions?.Articles.Edit,
                        icon: () => <FontAwesomeIcon icon={faUpload} />,
                        tooltip: `Publish All Articles`,
                        isFreeAction: true,
                    },
                    {
                        onClick: () => history('/app-config/articles/new'),
                        hidden: !pagePermissions?.Articles.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Article',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/articles/${id}`),
                        hidden: !pagePermissions?.Articles.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Article',
                    },
                    {
                        onClick: (_e, article) => {
                            setArticleName((article as Article).name);
                            setArticleId((article as Article).id);
                            setShowCloneModal(true);
                        },
                        icon: () => <FontAwesomeIcon icon={faClone} />,
                        tooltip: 'Clone',
                    },
                    {
                        onClick: (_e, article) => {
                            setArticleName((article as Article).name);
                            handleShowUsage((article as Article).id);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Article Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },

                    {
                        title: 'Name',
                        field: 'name',
                        customSort: (a, b) => {
                            if (a.name === undefined || b.name === undefined) {
                                return 0;
                            }
                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                        },
                    },
                    { title: 'Category', field: 'articleCategory.name' },
                    { title: 'Description', field: 'description' },
                    { title: 'Keywords', field: 'keywords' },
                    {
                        title: 'Unpublished Changes',
                        field: 'changesToPublish',
                        align: 'center',
                        render: ({ changesToPublish }) => (
                            <Checkbox checked={changesToPublish} disabled />
                        ),
                    },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100], search: false }}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${articleName}`}
                    canDelete={pagePermissions?.Articles.Delete || false}
                    usageData={whereUsedData?.articleWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
            <Dialog scroll="paper" open={!cloneArticleLoading && showCloneModal}>
                <DialogTitleWithClose onClose={() => setShowCloneModal(false)} id="cloneTitle">
                    Clone &apos;{articleName}&apos;
                </DialogTitleWithClose>
                <CloneArticleModal
                    existingArticleId={articleId}
                    submitHandler={handleCloneArticleSubmit}
                />
            </Dialog>
        </div>
    );
};

export default Articles;
