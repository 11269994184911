import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import _ from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import ArticlePicker from '~/components/ArticlePicker/ArticlePicker';
import ContentIconSelect from '~/components/ContentIconSelect/ContentIconSelect';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { CLOUDFRONT_IMAGE_SOURCE } from '~/constants';
import {
    ContentIcon,
    ContentType,
    SimpleContentListDocument,
    SimpleContentListQuery,
    TrackerType,
    useCreateSimpleContentMutation,
    useSelectListsQuery,
    useUpdateSimpleContentMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { useStyles } from '../styles';

type SimpleContent = NonNullable<SimpleContentListQuery['simpleContentsV2']>['results'][0];

interface FormInput {
    name: string;
    content: string;
    label?: {
        en: string;
        es?: string;
    };
    description?: {
        en: string;
        es?: string;
    };
}

const toTrackerTypeName = (tracker: TrackerType): string => {
    switch (tracker) {
        case TrackerType.BabyBoost:
            return 'Baby Boost';
        case TrackerType.BloodPressure:
            return 'Blood Pressure';
        case TrackerType.Daily:
            return 'Daily';
        case TrackerType.Diaper:
            return 'Diaper';
        case TrackerType.Feeding:
            return 'Feeding';
        case TrackerType.FetalMovement:
            return 'Fetal Movement';
        case TrackerType.Glucose:
            return 'Glucose';
        case TrackerType.Growth:
            return 'Growth';
        case TrackerType.Height:
            return 'Height';
        case TrackerType.MoodBoost:
            return 'Mood Boost';
        case TrackerType.Ovulation:
            return 'Ovulation';
        case TrackerType.PregnancyWeight:
            return 'Pregnancy Weight';
        case TrackerType.Weight:
            return 'Weight';
        case TrackerType.Vaccination:
            return 'Vaccination';
        default:
            return 'Unknown';
    }
};

const toContentTypeName = (type: ContentType): string => {
    switch (type) {
        case ContentType.ExternalLink:
            return 'External Link';
        case ContentType.ArticleLink:
            return 'Article Link';
        case ContentType.Image:
            return 'Image';
        case ContentType.PhoneNumber:
            return 'Phone Number';
        case ContentType.Tracker:
            return 'Tracker Link';
        case ContentType.SurveyLink:
            return 'Survey Link';
        case ContentType.ContentDirectoryLink:
            return 'Content Directory Link';
        case ContentType.AppointmentHistory:
            return 'Appointment History Link';
        case ContentType.CareTeam:
            return 'Care Team Link';
        case ContentType.BabyBoostLink:
            return 'Baby Boost Link';
        case ContentType.MessageCenter:
            return 'Message Center Link';
        case ContentType.QListLink:
            return 'Question List Link';
        case ContentType.WeekByWeek:
            return 'Week-By-Week';
        case ContentType.WhoToCall:
            return 'Who To Call Link';
        case ContentType.Milestones:
            return 'Milestones';
        case ContentType.LearnLibrary:
            return 'Learn Library';
        case ContentType.Surveys:
            return 'Surveys';
        case ContentType.DueDateCalculator:
            return 'Due Date Calculator';
        case ContentType.Highlights:
            return 'Highlights Link';
        case ContentType.Todolist:
            return 'To Do List Link';
        case ContentType.ExploreScreen:
            return 'Explore Screen Link';
        case ContentType.Resources:
            return 'Resources Screen Link';
        case ContentType.TrackerView:
            return 'Trackers Screen Link';
        case ContentType.AddDependentProfile:
            return 'Add Dependent User Link';
        case ContentType.UserBio:
            return 'User Bio Link';
        case ContentType.Video:
            return 'Video';
        case ContentType.EligibilityCheckLink:
            return 'Eligibility Check Link';
        default:
            return 'Unknown';
    }
};

const inputValidation = Yup.object().shape({
    name: Yup.string().required('You need to enter a name'),
});

export const SimpleContentModal: React.FC<{
    item: SimpleContent | null;
    onClose: () => void;
}> = props => {
    const { classes } = useStyles();
    const { item, onClose } = props;
    const isInEditMode = item !== null;
    const [selectedType, setSelectedType] = useState<ContentType>(
        item?.contentType ?? ContentType.ExternalLink,
    );
    const [selectedIcon, setSelectedIcon] = useState<ContentIcon>(item?.icon ?? ContentIcon.None);
    const [selectedTracker, setSelectedTracker] = useState<TrackerType>(
        item?.trackerType ?? TrackerType.BabyBoost,
    );
    const [selectedArticleId, setSelectedArticleId] = useState<string>(item?.articleId ?? '');
    const [articleRequired, setArticleRequired] = useState<boolean>(false);
    const [selectedSurvey, setSelectedSurvey] = useState<string>(item?.surveyId ?? '');
    const [selectedCDId, setSelectedCDId] = useState<string>(item?.contentDirectoryId ?? '');
    const [selectedCareTeamId, setSelectedCareTeamId] = useState<string>(
        item?.careTeamMemberTypeId ?? '',
    );
    const { data: listData, loading } = useSelectListsQuery({
        onCompleted: ({ surveyDefs, contentDirectorys }) => {
            if (!item && surveyDefs && surveyDefs.length) {
                setSelectedSurvey(_.sortBy(surveyDefs, 'name')[0].id);
            }

            if (!item && contentDirectorys && contentDirectorys.length) {
                setSelectedCDId(_.sortBy(contentDirectorys, 'name')[0].id);
            }
        },
    });

    const {
        register,
        handleSubmit,
        watch,

        formState: { errors },
    } = useForm<FormInput>({
        resolver: yupResolver(inputValidation as any),
    });

    const handleClose = () => {
        onClose();
    };
    const [createItem, { loading: createLoading }] = useCreateSimpleContentMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem saving the simple content: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.createSimpleContent?.resourceCreated;
            if (response.data?.createSimpleContent?.success && newItem) {
                const currentItems = cache.readQuery<SimpleContentListQuery>({
                    query: SimpleContentListDocument,
                });
                if (currentItems?.simpleContentsV2.results) {
                    cache.writeQuery<SimpleContentListQuery>({
                        query: SimpleContentListDocument,
                        data: {
                            simpleContentsV2: {
                                ...currentItems.simpleContentsV2,
                                results: [...currentItems.simpleContentsV2.results, newItem],
                            },
                        },
                    });
                }
            }
        },
    });

    const [updateItem, { loading: updateLoading }] = useUpdateSimpleContentMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem updating the simple content: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.updateSimpleContent?.resourceUpdated;
            if (response.data?.updateSimpleContent?.success && newItem) {
                const currentItems = cache.readQuery<SimpleContentListQuery>({
                    query: SimpleContentListDocument,
                });
                if (currentItems?.simpleContentsV2.results) {
                    const updatedList = currentItems.simpleContentsV2.results.map(item => {
                        if (item.id === newItem.id) {
                            return newItem;
                        }
                        return item;
                    });
                    cache.writeQuery<SimpleContentListQuery>({
                        query: SimpleContentListDocument,
                        data: {
                            simpleContentsV2: {
                                ...currentItems.simpleContentsV2,
                                results: [...updatedList],
                            },
                        },
                    });
                }
            }
        },
    });

    const onSubmit = (values: FormInput) => {
        if (selectedType === ContentType.ArticleLink && !selectedArticleId) {
            setArticleRequired(true);
            return;
        }

        if (isInEditMode && item !== null) {
            updateItem({
                variables: {
                    updateSimpleContentInput: {
                        id: item.id,
                        data: {
                            ...values,
                            contentType: selectedType,
                            icon: selectedIcon,
                            trackerType:
                                selectedType === ContentType.Tracker ? selectedTracker : undefined,
                            articleId:
                                selectedType === ContentType.ArticleLink
                                    ? selectedArticleId
                                    : undefined,
                            surveyId:
                                selectedType === ContentType.SurveyLink
                                    ? selectedSurvey
                                    : undefined,
                            contentDirectoryId:
                                selectedType === ContentType.ContentDirectoryLink
                                    ? selectedCDId
                                    : undefined,
                            careTeamMemberTypeId:
                                selectedType === ContentType.MessageCenter
                                    ? selectedCareTeamId
                                    : undefined,
                        },
                    },
                },
            });
            return;
        }
        createItem({
            variables: {
                input: {
                    ...values,
                    contentType: selectedType,
                    icon: selectedIcon,
                    trackerType: selectedType === ContentType.Tracker ? selectedTracker : undefined,
                    articleId:
                        selectedType === ContentType.ArticleLink ? selectedArticleId : undefined,
                    surveyId: selectedType === ContentType.SurveyLink ? selectedSurvey : undefined,
                    contentDirectoryId:
                        selectedType === ContentType.ContentDirectoryLink
                            ? selectedCDId
                            : undefined,
                    careTeamMemberTypeId:
                        selectedType === ContentType.MessageCenter ? selectedCareTeamId : undefined,
                },
            },
        });
    };

    return (
        <Dialog open>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                {isInEditMode ? 'Edit' : 'Add'} Simple Content
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {loading || updateLoading || createLoading ? (
                        <Loading height={50} />
                    ) : (
                        <>
                            <div>
                                Content Type:{' '}
                                <Autocomplete
                                    size="small"
                                    disabled={isInEditMode}
                                    value={selectedType}
                                    onChange={(_, val) => setSelectedType(val as ContentType)}
                                    getOptionLabel={type => toContentTypeName(type)}
                                    options={Object.values(ContentType)}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Name *"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={item?.name}
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            </div>
                            {selectedType === ContentType.ExternalLink && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="External Link *"
                                        fullWidth
                                        multiline
                                        margin="dense"
                                        defaultValue={item?.content}
                                        {...register('content')}
                                        error={!!errors.content}
                                        helperText={errors.content?.message}
                                    />
                                </div>
                            )}
                            {selectedType === ContentType.PhoneNumber && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="tel"
                                        label="Phone Number *"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={item?.content}
                                        {...register('content')}
                                        error={!!errors.content}
                                        helperText={errors.content?.message}
                                    />
                                </div>
                            )}
                            {selectedType === ContentType.Image && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="Image (file name &amp; extension) *"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={item?.content}
                                        {...register('content')}
                                        error={!!errors.content}
                                        helperText={errors.content?.message}
                                    />
                                    {item?.content ? (
                                        <Grid className={classes.simpleContentImageCard}>
                                            <Card variant="outlined">
                                                <CardMedia
                                                    component="img"
                                                    image={`${CLOUDFRONT_IMAGE_SOURCE}${
                                                        watch('content') || item?.content
                                                    }`}
                                                    alt="Simple content image"
                                                />
                                            </Card>
                                        </Grid>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            )}
                            {selectedType === ContentType.Video && (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="Video (file name &amp; extension) *"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={item?.content}
                                        {...register('content')}
                                        error={!!errors.content}
                                        helperText={errors.content?.message}
                                    />
                                </div>
                            )}
                            {selectedType === ContentType.ArticleLink && (
                                <ArticlePicker
                                    selectedArticleId={selectedArticleId}
                                    setSelectedArticleId={setSelectedArticleId}
                                    required={articleRequired}
                                    setRequired={setArticleRequired}
                                    compact
                                />
                            )}
                            {selectedType === ContentType.SurveyLink && (
                                <OutlinedSection title="Select Survey">
                                    <select
                                        value={selectedSurvey}
                                        onChange={e => setSelectedSurvey(e.target.value)}
                                    >
                                        {_.sortBy(listData?.surveyDefs, 'name').map(a => (
                                            <option key={a.id} value={a.id}>
                                                {a.name}
                                            </option>
                                        ))}
                                    </select>
                                </OutlinedSection>
                            )}
                            {selectedType === ContentType.ContentDirectoryLink && (
                                <OutlinedSection title="Select Content Directory">
                                    <select
                                        value={selectedCDId}
                                        onChange={e => setSelectedCDId(e.target.value)}
                                    >
                                        {_.sortBy(listData?.contentDirectorys, 'name').map(a => (
                                            <option key={a.id} value={a.id}>
                                                {a.name}
                                            </option>
                                        ))}
                                    </select>
                                </OutlinedSection>
                            )}

                            {selectedType === ContentType.Tracker && (
                                <OutlinedSection title="Tracker Type">
                                    <select
                                        value={selectedTracker}
                                        onChange={e =>
                                            setSelectedTracker(e.target.value as TrackerType)
                                        }
                                    >
                                        {Object.values(TrackerType).map(t => (
                                            <option key={t} value={t}>
                                                {toTrackerTypeName(t)}
                                            </option>
                                        ))}
                                    </select>
                                </OutlinedSection>
                            )}
                            {selectedType === ContentType.MessageCenter && (
                                <OutlinedSection title="Message Center Care Team Type">
                                    <select
                                        value={selectedCareTeamId}
                                        onChange={e => setSelectedCareTeamId(e.target.value)}
                                    >
                                        <option value="" key="">
                                            No Specific Team
                                        </option>
                                        {_.sortBy(listData?.careTeamMemberTypes, 'name').map(a => (
                                            <option key={a.id} value={a.id}>
                                                {a.name}
                                            </option>
                                        ))}
                                    </select>
                                </OutlinedSection>
                            )}
                            <ContentIconSelect
                                selectedIcon={selectedIcon}
                                setSelectedIcon={setSelectedIcon}
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onEdit={() => {}}
                            />
                            <OutlinedSection title="Label">
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="English"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={item?.label?.en}
                                        {...register('label.en')}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="Spanish"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={item?.label?.es}
                                        {...register('label.es')}
                                    />
                                </div>
                            </OutlinedSection>
                            <OutlinedSection title="Description">
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="English"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={item?.description?.en}
                                        {...register('description.en')}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="Spanish"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={item?.description?.es}
                                        {...register('description.es')}
                                    />
                                </div>
                            </OutlinedSection>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={ConditionTypeEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
