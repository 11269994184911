import React from 'react';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../styles';
import { ProcessingError } from '../types';

type ErrorDialogProps = {
    open: boolean;
    onClose: () => void;
    processingError?: ProcessingError;
};

const ErrorDialog: React.FC<ErrorDialogProps> = props => {
    const { open, onClose, processingError } = props;
    const { classes } = useStyles();
    return (
        <Dialog open={open} disableEscapeKeyDown>
            <DialogTitle textAlign="right">
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {processingError && (
                <>
                    <DialogContent dividers>
                        <Container fixed className={classes.dialogContent}>
                            <Box className={classes.errorList}>
                                <Typography
                                    variant="body1"
                                    dangerouslySetInnerHTML={{
                                        __html: processingError?.message || '',
                                    }}
                                />
                                {processingError.data?.map(d => (
                                    <Typography variant="body2" key={d}>
                                        {d}
                                    </Typography>
                                ))}
                            </Box>
                            {processingError.message
                                ?.toLowerCase()
                                .includes('not able to match the following patients') && (
                                <Typography>
                                    <Link
                                        href="/affiliate-care-portal/patients"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="hover"
                                    >
                                        Compare to Patient List
                                    </Link>
                                </Typography>
                            )}
                        </Container>
                        <Typography variant="body1">Please fix issues and resubmit.</Typography>
                    </DialogContent>
                    <DialogActions className={classes.dialogButtons}>
                        <Button color="secondary" onClick={onClose}>
                            Close
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default ErrorDialog;
