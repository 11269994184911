import React, { useRef, useState } from 'react';
import { AlertSeverity } from '~/schemaTypes';
import MaterialTable from '@material-table/core';
import tableIcons from '~/helpers/tableIcons';
import { Dialog, IconButton, Link as MuiLink, TablePagination, Tooltip } from '@mui/material';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import { Add, InfoOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useUserPermissions } from '~/hooks';
import copy from 'copy-to-clipboard';
import { TriggerGlobalAlert } from '~/state';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileDefsModal from '~/views/ConfigDashboard/ProfileDefs/ProfileDefsModal/ProfileDefsModal';
import { COMPOUND_QUESTION_KEY } from './const';
import CompoundQuestionModal from './CompoundQuestionModel/CompoundQuestionModal';
import useCompoundQuestionsSearch from './useCompoundQuestionsSearch';
import { CompoundQuestion } from './types';

const CompoundQuestions: React.FC = () => {
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const { id } = useParams();
    const [isOpenModal, setIsOpenModal] = useState<boolean>(!!id);
    const [profileDefId, setProfileDefId] = useState<string | null>(null);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const { data, total, loading, rowsPerPage, setRowsPerPage, page, setPage, setFilter } =
        useCompoundQuestionsSearch({
            onCompletedDataLoad: () => {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            },
        });

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        page: number,
    ): void => {
        event?.preventDefault();
        setPage(page);
        tableRef?.current?.scrollIntoView();
    };

    const handleIdClick = (id: string): void => {
        copy(id);
        TriggerGlobalAlert({
            message: 'Id copied to clipboard',
            severity: AlertSeverity.Success,
        });
    };

    const handleSetFilter = (value: IFilterChange<CompoundQuestion>): void => {
        setFilter(value);
        setPage(0);
    };

    return (
        <div ref={tableRef}>
            <MaterialTable<CompoundQuestion>
                title="Compound Questions"
                icons={tableIcons}
                isLoading={loading}
                data={data}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={total}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        onClick: event => event.preventDefault(),
                        hidden: !pagePermissions?.CompoundQuestions.Edit,
                        icon: () => (
                            <CustomFilter<CompoundQuestion>
                                setFilter={handleSetFilter}
                                defaultField={COMPOUND_QUESTION_KEY.LABEL}
                                fields={[
                                    COMPOUND_QUESTION_KEY.LABEL,
                                    COMPOUND_QUESTION_KEY.PROFILE_DEF_KEY,
                                ]}
                                fieldNames={{
                                    [COMPOUND_QUESTION_KEY.LABEL]: 'Label',
                                    [COMPOUND_QUESTION_KEY.PROFILE_DEF_KEY]: 'Profile Definition',
                                }}
                            />
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: () => setIsOpenModal(true),
                        icon: () => <Add />,
                        tooltip: 'Add Compound Question',
                        isFreeAction: true,
                        hidden: !pagePermissions?.CompoundQuestions.Edit,
                    },
                    {
                        onClick: (_, item: CompoundQuestion) => {
                            setIsOpenModal(true);
                            history(`/app-config/compound-questions/${item.id}`);
                        },
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Compound Question',
                        hidden: !pagePermissions?.CompoundQuestions.Edit,
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton onClick={() => handleIdClick(id)} size="large">
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                        sorting: false,
                    },
                    {
                        title: 'Label',
                        field: 'label.en',
                    },
                    {
                        title: 'Profile Value Type',
                        field: 'profileValueType',
                        sorting: false,
                    },
                    {
                        title: 'Profile Definitions',
                        field: 'profileValueType',
                        sorting: false,
                        render: item =>
                            item.questionProfileDefs
                                .map(i => ({
                                    id: i.questionProfileDef?.id ?? '',
                                    name: i.questionProfileDef?.name ?? '',
                                }))
                                .reduce((accum, item) => {
                                    if (!accum.some(i => i.id === item.id)) {
                                        accum.push(item);
                                    }
                                    return accum;
                                }, [] as { id: string; name: string }[])
                                .map(i => (
                                    <div key={i.id}>
                                        <MuiLink
                                            underline="hover"
                                            onClick={() => setProfileDefId(i.id ?? null)}
                                        >
                                            {i.name}
                                        </MuiLink>
                                    </div>
                                )),
                    },
                ]}
                onRowsPerPageChange={setRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                }}
            />
            {isOpenModal && (
                <CompoundQuestionModal
                    id={id ?? null}
                    compoundQuestion={id ? data.find(item => item.id === id) ?? null : null}
                    onClose={() => {
                        setIsOpenModal(false);
                        history('/app-config/compound-questions');
                    }}
                />
            )}
            <Dialog scroll="body" open={!!profileDefId} fullWidth maxWidth="lg">
                <ProfileDefsModal
                    id={profileDefId ?? ''}
                    setOpen={() => setProfileDefId(null)}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    setEditProfileDefId={() => {}}
                />
            </Dialog>
        </div>
    );
};

export default CompoundQuestions;
