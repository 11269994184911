import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    DestinationNames,
    useCheckEligibilityVirtualCareFormMutation,
    useCreateOrUpdateVirtualCareFormMutation,
    useFetchVirtualCareAffiliateByAffiliateIdQuery,
    useVcfSettingsVirtualCareFormQuery,
} from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert, TriggerGlobalBanner } from '~/state';
import SuccessScreen from '~/views/VirtualCareSurvey/steps/SuccessScreen';
import { VirtualCareSurveyInput } from './types';
import VirtualCareSurveySchemas from './yupSchema';
import PatientInfo from './steps/PatientInfo';
import PatientInfoContact from './steps/PatientInfoContact';
import Confirmation from './steps/Confirmation';
import { preparePayload, generateBaseUrl } from './helpers';
import AppointmentMethod from './steps/AppointmentMethod';
import PacifyConfirmation from './steps/PacifyConfirmation';
import AffiliateThank from './steps/AffiliateThank';
import useStyles from './styles';
import Header from './components/Header';

SuppressNextGlobalAlert(true);

enum Steps {
    PATIENT_INFO = 1,
    PATIENT_INFO_CONTACT = 2,
    APPOINTMENT_METHOD = 3,
    CONFIRMATION = 4,
    PACIFY_CONFIRMATION = 5,
    AFFILIATE_THANK = 6,
    SUCCESS_SCREEN = 7,
}

const defaultStepState = {
    [Steps.PATIENT_INFO]: false,
    [Steps.PATIENT_INFO_CONTACT]: false,
    [Steps.APPOINTMENT_METHOD]: false,
    [Steps.CONFIRMATION]: true,
    [Steps.PACIFY_CONFIRMATION]: true,
    [Steps.AFFILIATE_THANK]: true,
    [Steps.SUCCESS_SCREEN]: false,
};

const validStepsForView = new Map<Steps, Steps[]>([
    [Steps.PATIENT_INFO, []],
    [Steps.PATIENT_INFO_CONTACT, [Steps.PATIENT_INFO]],
    [Steps.APPOINTMENT_METHOD, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
    [Steps.CONFIRMATION, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
    [Steps.PACIFY_CONFIRMATION, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
    [Steps.AFFILIATE_THANK, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
    [Steps.SUCCESS_SCREEN, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
]);

type VirtualCareSurveyProps = {
    baseUrl?: string;
};

export const VirtualCareSurvey: React.FC<VirtualCareSurveyProps> = ({ baseUrl }) => {
    const { step: routeStepId = '1', affiliateId } = useParams<{
        step: string;
        affiliateId?: string;
    }>();
    const isAffiliateView = Boolean(affiliateId);
    const history = useNavigate();
    const { classes } = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [eligibilityRequestId, setEligibilityRequestId] = useState<string | null>(null);
    const [patientId, setPatientId] = useState<string>();
    const [selfSelected, setSelfSelected] = React.useState<boolean>(true);
    const [step, setStep] = React.useState<Steps>(Steps.PATIENT_INFO);
    const [showLoading, setShowLoading] = React.useState<boolean>(false);
    const [validSteps, setValidSteps] = React.useState<Record<Steps, boolean>>(defaultStepState);
    const [customSuccessText, setCustomSuccessText] = React.useState<string>();
    const selfCheck = searchParams.get('sc') === 'true';
    const setStepHandler = React.useCallback(
        (step: Steps) => {
            const pathname = generateBaseUrl(isAffiliateView, affiliateId, baseUrl);
            const fullPathname = `${pathname}/${step}`;
            const search = new URLSearchParams(searchParams).toString();
            history({ pathname: fullPathname, search });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliateId, isAffiliateView, baseUrl, searchParams],
    );
    useEffect(() => {
        const routeStepIdNumber = parseInt(routeStepId, 10);
        const shouldValidSteps = validStepsForView.get(routeStepIdNumber);
        if (shouldValidSteps) {
            const isFilledForm = shouldValidSteps.reduce((isFilledForm, step) => {
                if (isFilledForm) {
                    return validSteps[step];
                }
                return isFilledForm;
            }, true);
            if (isFilledForm) {
                setStep(routeStepIdNumber);
                return;
            }
        }
        setStepHandler(Steps.PATIENT_INFO);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeStepId]);
    const isMobileView = useMediaQuery('(max-width:900px)');

    const { data: VCAffiliate, loading: VCAffiliateFetching } =
        useFetchVirtualCareAffiliateByAffiliateIdQuery({
            variables: {
                input: {
                    affiliateId: affiliateId ?? '',
                },
            },
            onError: () => {
                TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Fetching failed' });
            },
            onCompleted: () => {
                setShowLoading(false);
            },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            skip: !isAffiliateView,
        });
    const [shouldShowForm, setShouldShowForm] = useState(false);
    useEffect(() => {
        if (VCAffiliate?.virtualCareAffiliateByAffiliateId?.data) {
            setShouldShowForm(true);
        }
    }, [VCAffiliate]);

    const formContainer = React.useRef<HTMLDivElement>(null);
    const predefinedFirstName = searchParams.get('firstName') ?? '';
    const predefinedLastName = searchParams.get('lastName') ?? '';
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        resetField,
        watch,
        getValues,
        reset,
    } = useForm<VirtualCareSurveyInput>({
        resolver: yupResolver(VirtualCareSurveySchemas(isAffiliateView, selfCheck)[step] as any),
        defaultValues: {
            insuredAddressSameAsPatient: true,
            state: '',
            insuredState: '',
            babyMultiples: undefined,
            babyArrived: undefined,
        },
    });
    const { data: VCFSettings } = useVcfSettingsVirtualCareFormQuery({});
    const enableAppointmentStep = true;
    const [
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        sex,
        birthDate,
        insuredAddressSameAsPatient,
        appointmentMethod,
        insuredState,
    ] = watch([
        'firstName',
        'lastName',
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'zipCode',
        'sex',
        'birthDate',
        'insuredAddressSameAsPatient',
        'appointmentMethod',
        'insuredState',
    ]);
    const atHomeOptionEnabled = useMemo(
        () => Boolean(VCFSettings?.VCFSettings?.showOnlyAtHomeZipCodes?.includes(zipCode)),
        [VCFSettings?.VCFSettings?.showOnlyAtHomeZipCodes, zipCode],
    );
    const getNextStep = useCallback(
        (step: Steps) => {
            switch (step) {
                case Steps.PATIENT_INFO:
                    return Steps.PATIENT_INFO_CONTACT;
                case Steps.PATIENT_INFO_CONTACT:
                    if (isAffiliateView) {
                        return false;
                    }
                    if (enableAppointmentStep && atHomeOptionEnabled) {
                        return Steps.APPOINTMENT_METHOD;
                    }
                    return false;
                case Steps.APPOINTMENT_METHOD:
                    return false;
                default:
                    return false;
            }
        },
        [enableAppointmentStep, atHomeOptionEnabled, isAffiliateView],
    );
    const [createOrUpdate, { loading: createVCFLoading }] =
        useCreateOrUpdateVirtualCareFormMutation({
            onCompleted: data => {
                if (data.createOrUpdateVirtualCareForm?.data) {
                    const {
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        birthDate,
                        patientId,
                        Destination,
                    } = data.createOrUpdateVirtualCareForm.data;
                    if (
                        !firstName ||
                        !lastName ||
                        !email ||
                        !phoneNumber ||
                        !patientId ||
                        !birthDate
                    ) {
                        TriggerGlobalAlert({
                            severity: AlertSeverity.Error,
                            message: 'Something went wrong. Please try again.',
                        });
                        return;
                    }
                    const { redirectUrl, successMessage } = data.createOrUpdateVirtualCareForm;
                    setPatientId(patientId);
                    if (Destination === DestinationNames.WIldflower && redirectUrl) {
                        window.location.href = redirectUrl;
                    } else if (Destination === DestinationNames.RouteToSuccessScreen) {
                        setCustomSuccessText(successMessage || '');
                        setStepHandler(Steps.SUCCESS_SCREEN);
                        setShowLoading(false);
                        reset();
                    } else if (Destination === DestinationNames.Affiliate) {
                        setStepHandler(Steps.AFFILIATE_THANK);
                        setShowLoading(false);
                        reset();
                    } else if (Destination === redirectUrl && redirectUrl != null) {
                        window.location.href = redirectUrl;
                    } else {
                        setShowLoading(false);
                    }
                }
            },
            onError: error => {
                setShowLoading(false);
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
        });
    const [checkEligibility, { loading: checkEligibilityLoading }] =
        useCheckEligibilityVirtualCareFormMutation({
            onCompleted: data => {
                if (data.checkVCFEligibility?.isEligible) {
                    setEligibilityRequestId(data.checkVCFEligibility?.resultId ?? null);
                    setValidSteps(prev => ({
                        ...prev,
                        [Steps.PATIENT_INFO]: true,
                    }));
                    const nextStep = getNextStep(Steps.PATIENT_INFO);
                    if (nextStep !== false) {
                        setStepHandler(nextStep);
                    }
                } else if (data.checkVCFEligibility?.message === 'Eligibility failed') {
                    TriggerGlobalBanner({
                        open: true,
                        severity: AlertSeverity.Error,
                        actionText: '',
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        callback: () => {},
                        message: `Based on the Primary Insurance information provided, you are not eligible for these services. Please verify that your Primary Insurance Information supplied is correct and then try again. If you believe that you should be eligible, please contact our support at: lactationsupport@wildflowerhealth.com`,
                    });
                } else if (data.checkVCFEligibility?.message) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: data.checkVCFEligibility?.message,
                    });
                }
            },
        });

    const showFillPrevStepAlert = useCallback(() => {
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: 'Please fill the previous step. Click on "Back" button',
        });
    }, []);
    const formSteps = useMemo(() => {
        if (enableAppointmentStep) {
            return [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT, Steps.APPOINTMENT_METHOD];
        }
        return [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT];
    }, [enableAppointmentStep]);
    const onSubmit = (data: VirtualCareSurveyInput, skipValidationCheck?: boolean) => {
        if (!skipValidationCheck && formSteps.filter(s => !validSteps[s]).length > 0) {
            showFillPrevStepAlert();
            return;
        }
        const affiliateData = { id: affiliateId, selfCheck, eligibilityRequestId };
        const payload = preparePayload(
            data,
            insuredAddressSameAsPatient,
            isAffiliateView,
            affiliateData,
        );
        SuppressNextGlobalAlert(true);
        createOrUpdate({
            variables: {
                input: payload,
            },
        });
    };
    const isFormStep = formSteps.includes(step);
    useEffect(() => {
        if (insuredAddressSameAsPatient) {
            // copy values to insured fields
            setValue('insuredAddressLine1', addressLine1, { shouldValidate: true });
            setValue('insuredAddressLine2', addressLine2, { shouldValidate: true });
            setValue('insuredCity', city, { shouldValidate: true });
            setValue('insuredState', state, { shouldValidate: true });
            setValue('insuredZipCode', zipCode, { shouldValidate: true });
        } else {
            resetField('insuredAddressLine1', { keepDirty: true });
            resetField('insuredAddressLine2', { keepDirty: true });
            resetField('insuredCity', { keepDirty: true });
            setValue('insuredState', '');
            resetField('insuredZipCode', { keepDirty: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuredAddressSameAsPatient]);
    useEffect(() => {
        if (insuredAddressSameAsPatient) {
            setValue('insuredAddressLine1', addressLine1);
            setValue('insuredAddressLine2', addressLine2);
            setValue('insuredCity', city);
            setValue('insuredState', state);
            setValue('insuredZipCode', zipCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressLine1, addressLine2, city, state, zipCode]);
    useEffect(() => {
        if (selfSelected) {
            setValue('insuredFirstName', firstName);
            setValue('insuredLastName', lastName);
            setValue('subscriberSex', sex);
            setValue('subscriberBirthDate', birthDate);
        } else {
            resetField('insuredFirstName', { keepDirty: true });
            resetField('insuredLastName', { keepDirty: true });
            resetField('subscriberSex', { keepDirty: true, defaultValue: '' });
            resetField('subscriberBirthDate', { keepDirty: true, defaultValue: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selfSelected]);
    useEffect(() => {
        if (selfSelected) {
            setValue('insuredFirstName', firstName);
            setValue('insuredLastName', lastName);
            setValue('subscriberSex', sex);
            setValue('subscriberBirthDate', birthDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, lastName, sex, birthDate]);
    useEffect(() => {
        formContainer?.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [step]);
    useEffect(() => {
        if (createVCFLoading || VCAffiliateFetching) {
            setShowLoading(true);
        }
    }, [createVCFLoading, VCAffiliateFetching]);
    useEffect(() => {
        setValue('firstName', predefinedFirstName);
        setValue('lastName', predefinedLastName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [predefinedLastName, predefinedFirstName]);
    const firstStepHandler = useCallback(
        (data: VirtualCareSurveyInput) => {
            SuppressNextGlobalAlert(true);
            const payload = preparePayload(data, insuredAddressSameAsPatient);
            checkEligibility({
                variables: {
                    input: payload,
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [insuredAddressSameAsPatient],
    );
    const secondStepHandler = (data: VirtualCareSurveyInput) => {
        if (!validSteps[Steps.PATIENT_INFO]) {
            showFillPrevStepAlert();
            return;
        }
        setValidSteps(prev => ({
            ...prev,
            [Steps.PATIENT_INFO_CONTACT]: true,
        }));
        const nextStep = getNextStep(Steps.PATIENT_INFO_CONTACT);
        if (nextStep !== false) {
            setStepHandler(nextStep);
        } else {
            onSubmit(data, true);
        }
    };
    const thirdStepHandler = (data: VirtualCareSurveyInput) => {
        if (!validSteps[Steps.PATIENT_INFO] || !validSteps[Steps.PATIENT_INFO_CONTACT]) {
            showFillPrevStepAlert();
            return;
        }
        setValidSteps(prev => ({
            ...prev,
            [Steps.APPOINTMENT_METHOD]: true,
        }));
        onSubmit(data, true);
    };
    const thankBackHandler = useCallback(() => {
        setSearchParams(state => {
            state.set('firstName', '');
            state.set('lastName', '');
            return state;
        });
        setStepHandler(Steps.PATIENT_INFO);
        setValidSteps(defaultStepState);
        setPatientId('');
        setEligibilityRequestId('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
    const showFormDescription = useMemo(
        () => [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT].includes(step),
        [step],
    );

    if (showLoading) {
        return <Loading />;
    }
    if (!shouldShowForm && isAffiliateView) {
        return (
            <Grid
                className={classes.form}
                container
                justifyContent={isMobileView ? 'flex-start' : 'center'}
            >
                <Header
                    isMobileView={isMobileView}
                    showFormDescription={false}
                    subHeading={
                        <Typography variant="body2" color="textSecondary">
                            Affiliate Not Found. If you think this is in error send an email to:
                            <Button href="Lactationsupport@wildflowerhealth.com">
                                Lactationsupport@wildflowerhealth.com
                            </Button>
                        </Typography>
                    }
                    isAffiliateView={isAffiliateView}
                />
            </Grid>
        );
    }
    return (
        <div className={classes.container} ref={formContainer}>
            <Grid
                className={classes.form}
                container
                justifyContent={isMobileView ? 'flex-start' : 'center'}
            >
                <Header
                    isMobileView={isMobileView}
                    showFormDescription={showFormDescription}
                    subHeading={
                        isAffiliateView ? (
                            <Typography variant="body1" mb={2}>
                                In Partnership with:{' '}
                                {VCAffiliate?.virtualCareAffiliateByAffiliateId?.data?.externalName}
                            </Typography>
                        ) : null
                    }
                    isAffiliateView={isAffiliateView}
                />
                <Grid container className={classes.formContainer}>
                    {isFormStep && (
                        <Grid item xs={12}>
                            <form className={classes.fullHeight}>
                                <Grid
                                    className={classes.fullHeight}
                                    alignItems="flex-start"
                                    container
                                    rowSpacing={{ md: 1, xs: 0 }}
                                >
                                    {step === Steps.PATIENT_INFO && (
                                        <PatientInfo
                                            register={register}
                                            classes={classes}
                                            control={control}
                                            errors={errors}
                                            setValue={setValue}
                                            stepHandler={handleSubmit(firstStepHandler)}
                                            isMobileView={isMobileView}
                                            setSelfSelected={setSelfSelected}
                                            stepLoading={checkEligibilityLoading}
                                            getValues={getValues}
                                            selfSelected={selfSelected}
                                        />
                                    )}
                                    {step === Steps.PATIENT_INFO_CONTACT && (
                                        <PatientInfoContact
                                            register={register}
                                            classes={classes}
                                            control={control}
                                            errors={errors}
                                            setValue={setValue}
                                            backHandler={() => setStepHandler(Steps.PATIENT_INFO)}
                                            sameAddress={insuredAddressSameAsPatient}
                                            stepHandler={handleSubmit(secondStepHandler)}
                                            isMobileView={isMobileView}
                                            stepLoading={showLoading}
                                            patientState={state}
                                            insuredState={insuredState}
                                            getValues={getValues}
                                            isAffiliateView={isAffiliateView}
                                            selfCheck={selfCheck}
                                        />
                                    )}
                                    {step === Steps.APPOINTMENT_METHOD && (
                                        <AppointmentMethod
                                            register={register}
                                            classes={classes}
                                            control={control}
                                            errors={errors}
                                            setValue={setValue}
                                            backHandler={() =>
                                                setStepHandler(Steps.PATIENT_INFO_CONTACT)
                                            }
                                            stepHandler={handleSubmit(thirdStepHandler)}
                                            isMobileView={isMobileView}
                                            stepLoading={showLoading}
                                            onlyAtHomeOption={atHomeOptionEnabled}
                                            type={appointmentMethod}
                                            getValues={getValues}
                                        />
                                    )}
                                </Grid>
                            </form>
                        </Grid>
                    )}
                    {step === Steps.CONFIRMATION && <Confirmation classes={classes} />}
                    {step === Steps.PACIFY_CONFIRMATION && (
                        <PacifyConfirmation
                            classes={classes}
                            isMobileView={isMobileView}
                            backHandler={() => setStepHandler(Steps.APPOINTMENT_METHOD)}
                        />
                    )}
                    {step === Steps.AFFILIATE_THANK && patientId && (
                        <AffiliateThank backHandler={thankBackHandler} patientId={patientId} />
                    )}
                    {step === Steps.SUCCESS_SCREEN && (
                        <SuccessScreen
                            customSuccessText={customSuccessText}
                            patientId={patientId ?? ''}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};
