import React, { memo } from 'react';
import copy from 'copy-to-clipboard';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography, Link, TextField, Tooltip, IconButton, Button } from '@mui/material';
import { TriggerGlobalAlert } from '~/state';
import { AlertSeverity } from '~/schemaTypes';
import { ContentCopy } from '@mui/icons-material';

type AffiliateUrlProps = {
    url: string;
    affiliateId: string;
};

const useStyles = makeStyles()({
    input: {
        padding: 0,
    },
    container: {
        maxWidth: '800px',
    },
});

const AffiliateUrl: React.FC<AffiliateUrlProps> = props => {
    const { url, affiliateId } = props;
    const { classes } = useStyles();
    const internalUrl = `/affiliate-care-portal/patients/${affiliateId}/1?sc=true`;
    return (
        <Grid
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            marginBottom={5}
            className={classes.container}
        >
            <Grid
                item
                xs={12}
                container
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Grid item>
                    <Typography variant="body1">
                        Sharable link to have patients check their own eligibility
                    </Typography>
                </Grid>
                <Grid container item alignItems="center" width="100%">
                    <Grid item xs={11}>
                        <TextField
                            disabled
                            value={url}
                            size="small"
                            className={classes.input}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title={url}>
                            <IconButton
                                onClick={() => {
                                    copy(`${url}`);
                                    TriggerGlobalAlert({
                                        message: 'VC Affiliate URL is copied to clipboard',
                                        severity: AlertSeverity.Success,
                                    });
                                }}
                                size="large"
                            >
                                <ContentCopy />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container justifyContent="space-evenly">
                <Grid item>
                    <Link href={internalUrl} underline="hover">
                        <Button variant="outlined">Check Patients Eligibility</Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Link href="/affiliate-care-portal/claims/build-claims" underline="hover">
                        <Button variant="outlined">Submit Claims for Reimbursement</Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default memo(AffiliateUrl);
