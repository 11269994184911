import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { Warning, Restore } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import tableIcons from '~/helpers/tableIcons';
import {
    MeasurementType,
    useFetchMeasurementsForPatientMeasurementsPageLazyQuery,
} from '~/schemaTypes';
import { TimePeriodFilter } from '~/views/Dashboard/Patients/Patient/PatientMeasurements/components/TimePeriodFilter';
import { Measurement } from '~/views/Dashboard/Patients/Patient/PatientMeasurements/PatientMeasurements';
import { BloodPressurePropsInterface } from '../interfaces';
import { useStyles } from './styles';

export enum MealOption {
    fasting = 'fasting',
    postBreakfast = 'postBreakfast',
    postLunch = 'postLunch',
    postDinner = 'postDinner',
}
export const mealOptionHeader = {
    [MealOption.fasting]: 'Fasting',
    [MealOption.postBreakfast]: 'After Breakfast',
    [MealOption.postLunch]: 'After Lunch',
    [MealOption.postDinner]: 'After Dinner',
};

const materialTableOptions = {
    paging: false,
};

const filterByMealOption = (measurements: Measurement[], option: MealOption): Measurement[] => {
    return measurements.filter(item => {
        const itemOption = item.value.bloodGlucose?.mealOption;
        return itemOption === option;
    });
};

type RouteParams = {
    id: string;
};

export const BloodGlucoseMeasurementTable: React.FC<BloodPressurePropsInterface> = ({
    data,
    isLoading,
    openHistory,
    setHistoryId,
}) => {
    const { classes } = useStyles();
    const tableData = data.length > 0 ? _.cloneDeep(data) : [];

    const { id } = useParams<RouteParams>();
    const [fetchMeasurements, { data: measurements, loading: measurementsLoading }] =
        useFetchMeasurementsForPatientMeasurementsPageLazyQuery();

    const onChangePeriod = (datePeriod: Date | null) => {
        fetchMeasurements({
            variables: {
                patientInput: {
                    id,
                },
                measurementsInput: {
                    filter: {
                        createdAfter: datePeriod?.toISOString(),
                        fields: {
                            type: MeasurementType.BloodGlucose,
                        },
                    },
                },
            },
        });
    };
    return (
        <Grid container>
            <TimePeriodFilter onSelectPeriod={onChangePeriod} />
            <Grid item xs={12} className={classes.measurementTable}>
                <MaterialTable
                    title={mealOptionHeader[MealOption.fasting]}
                    isLoading={isLoading || measurementsLoading}
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'Date of Test',
                            render: ({ takenDate, patient, timezoneName }) => (
                                <Typography>
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: takenDate,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone:
                                            timezoneName ??
                                            patient?.practice.timezone ??
                                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            ),
                            customSort: (a, b) =>
                                new Date(a.takenDate).getTime() - new Date(b.takenDate).getTime(),
                            defaultSort: 'desc',
                        },
                        {
                            title: 'Date of Report',
                            render: ({ updatedAt }) => (
                                <Typography>
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: updatedAt,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            ),
                            customSort: (a, b) =>
                                new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
                            defaultSort: 'desc',
                        },
                        {
                            title: 'BGL',
                            field: `value.${MeasurementType.BloodGlucose}.glucose`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                                externalId,
                                hasHistory,
                            }) => {
                                if (glucoseValue) {
                                    const { glucose } = glucoseValue;
                                    return (
                                        <div className={classes.horizontalAlign}>
                                            <Typography>{glucose}</Typography>
                                            {hasHistory && (
                                                <Tooltip title="History">
                                                    <IconButton
                                                        onClick={() => {
                                                            setHistoryId(externalId);
                                                            openHistory(true);
                                                        }}
                                                    >
                                                        <Restore />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                    );
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Flagged',
                            field: 'flagged',
                            render: ({ flagged }) => {
                                if (flagged) {
                                    return <Warning />;
                                }
                                return <div />;
                            },
                            customSort: (a, b) => Number(a.flagged) - Number(b.flagged),
                        },
                    ]}
                    data={
                        filterByMealOption(
                            measurements && measurements.patient?.measurements
                                ? _.cloneDeep(measurements.patient.measurements)
                                : tableData,
                            MealOption.fasting,
                        ) ?? []
                    }
                    options={materialTableOptions}
                />
            </Grid>
            <Grid item xs={12} className={classes.measurementTable}>
                <MaterialTable
                    title={mealOptionHeader[MealOption.postBreakfast]}
                    isLoading={isLoading || measurementsLoading}
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'Date of Test',
                            render: ({ takenDate, patient, timezoneName }) => (
                                <Typography>
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: takenDate,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone:
                                            timezoneName ??
                                            patient?.practice.timezone ??
                                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            ),
                            customSort: (a, b) =>
                                new Date(a.takenDate).getTime() - new Date(b.takenDate).getTime(),
                            defaultSort: 'desc',
                        },
                        {
                            title: 'Date of Report',
                            render: ({ updatedAt }) => (
                                <Typography>
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: updatedAt,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            ),
                            customSort: (a, b) =>
                                new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
                            defaultSort: 'desc',
                        },
                        {
                            title: 'BGL',
                            field: `value.${MeasurementType.BloodGlucose}.glucose`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                                externalId,
                                hasHistory,
                            }) => {
                                if (glucoseValue) {
                                    const { glucose } = glucoseValue;
                                    return (
                                        <div className={classes.horizontalAlign}>
                                            <Typography>{glucose}</Typography>
                                            {hasHistory && (
                                                <Tooltip title="History">
                                                    <IconButton
                                                        onClick={() => {
                                                            setHistoryId(externalId);
                                                            openHistory(true);
                                                        }}
                                                    >
                                                        <Restore />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                    );
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Meal Time',
                            field: `value.${MeasurementType.BloodGlucose}.mealTime`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                                patient,
                                timezoneName,
                            }) => {
                                if (glucoseValue) {
                                    const { mealTime } = glucoseValue;
                                    if (mealTime) {
                                        return (
                                            <Typography>
                                                {displayDateWithAbbrTimeZone({
                                                    isoDateStr: mealTime,
                                                    format: 'hh:mm A',
                                                    timeZone:
                                                        timezoneName ??
                                                        patient?.practice.timezone ??
                                                        Intl.DateTimeFormat().resolvedOptions()
                                                            .timeZone,
                                                })}
                                            </Typography>
                                        );
                                    }
                                    return <div />;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Meal Notes',
                            field: `value.${MeasurementType.BloodGlucose}.meal`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                            }) => {
                                if (glucoseValue) {
                                    const { meal } = glucoseValue;
                                    return <Typography>{meal}</Typography>;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Other Notes',
                            field: `value.${MeasurementType.BloodGlucose}.notes`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                            }) => {
                                if (glucoseValue) {
                                    const { notes } = glucoseValue;
                                    return <Typography>{notes}</Typography>;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Flagged',
                            field: 'flagged',
                            render: ({ flagged }) => {
                                if (flagged) {
                                    return <Warning />;
                                }
                                return <div />;
                            },
                            customSort: (a, b) => Number(a.flagged) - Number(b.flagged),
                        },
                    ]}
                    data={
                        filterByMealOption(
                            measurements && measurements.patient?.measurements
                                ? _.cloneDeep(measurements.patient.measurements)
                                : tableData,
                            MealOption.postBreakfast,
                        ) ?? []
                    }
                    options={materialTableOptions}
                />
            </Grid>
            <Grid item xs={12} className={classes.measurementTable}>
                <MaterialTable
                    title={mealOptionHeader[MealOption.postLunch]}
                    isLoading={isLoading || measurementsLoading}
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'Date of Test',
                            render: ({ takenDate, patient, timezoneName }) => (
                                <Typography>
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: takenDate,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone:
                                            timezoneName ??
                                            patient?.practice.timezone ??
                                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            ),
                            customSort: (a, b) =>
                                new Date(a.takenDate).getTime() - new Date(b.takenDate).getTime(),
                            defaultSort: 'desc',
                        },
                        {
                            title: 'Date of Report',
                            render: ({ updatedAt }) => (
                                <Typography>
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: updatedAt,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            ),
                            customSort: (a, b) =>
                                new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
                            defaultSort: 'desc',
                        },
                        {
                            title: 'BGL',
                            field: `value.${MeasurementType.BloodGlucose}.glucose`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                                externalId,
                                hasHistory,
                            }) => {
                                if (glucoseValue) {
                                    const { glucose } = glucoseValue;
                                    return (
                                        <div className={classes.horizontalAlign}>
                                            <Typography>{glucose}</Typography>
                                            {hasHistory && (
                                                <Tooltip title="History">
                                                    <IconButton
                                                        onClick={() => {
                                                            setHistoryId(externalId);
                                                            openHistory(true);
                                                        }}
                                                    >
                                                        <Restore />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                    );
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Meal Time',
                            field: `value.${MeasurementType.BloodGlucose}.mealTime`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                                patient,
                                timezoneName,
                            }) => {
                                if (glucoseValue) {
                                    const { mealTime } = glucoseValue;
                                    if (mealTime) {
                                        return (
                                            <Typography>
                                                {displayDateWithAbbrTimeZone({
                                                    isoDateStr: mealTime,
                                                    format: 'hh:mm A',
                                                    timeZone:
                                                        timezoneName ??
                                                        patient?.practice.timezone ??
                                                        Intl.DateTimeFormat().resolvedOptions()
                                                            .timeZone,
                                                })}
                                            </Typography>
                                        );
                                    }
                                    return <div />;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Meal Notes',
                            field: `value.${MeasurementType.BloodGlucose}.meal`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                            }) => {
                                if (glucoseValue) {
                                    const { meal } = glucoseValue;
                                    return <Typography>{meal}</Typography>;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Other Notes',
                            field: `value.${MeasurementType.BloodGlucose}.notes`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                            }) => {
                                if (glucoseValue) {
                                    const { notes } = glucoseValue;
                                    return <Typography>{notes}</Typography>;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Flagged',
                            field: 'flagged',
                            render: ({ flagged }) => {
                                if (flagged) {
                                    return <Warning />;
                                }
                                return <div />;
                            },
                            customSort: (a, b) => Number(a.flagged) - Number(b.flagged),
                        },
                    ]}
                    data={
                        filterByMealOption(
                            measurements && measurements.patient?.measurements
                                ? _.cloneDeep(measurements.patient.measurements)
                                : tableData,
                            MealOption.postLunch,
                        ) ?? []
                    }
                    options={materialTableOptions}
                />
            </Grid>
            <Grid item xs={12} className={classes.measurementTable}>
                <MaterialTable
                    title={mealOptionHeader[MealOption.postDinner]}
                    isLoading={isLoading || measurementsLoading}
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'Date of Test',
                            render: ({ takenDate, patient, timezoneName }) => (
                                <Typography>
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: takenDate,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone:
                                            timezoneName ??
                                            patient?.practice.timezone ??
                                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            ),
                            customSort: (a, b) =>
                                new Date(a.takenDate).getTime() - new Date(b.takenDate).getTime(),
                            defaultSort: 'desc',
                        },
                        {
                            title: 'Date of Report',
                            render: ({ updatedAt }) => (
                                <Typography>
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: updatedAt,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            ),
                            customSort: (a, b) =>
                                new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
                            defaultSort: 'desc',
                        },
                        {
                            title: 'BGL',
                            field: `value.${MeasurementType.BloodGlucose}.glucose`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                                externalId,
                                hasHistory,
                            }) => {
                                if (glucoseValue) {
                                    const { glucose } = glucoseValue;
                                    return (
                                        <div className={classes.horizontalAlign}>
                                            <Typography>{glucose}</Typography>
                                            {hasHistory && (
                                                <Tooltip title="History">
                                                    <IconButton
                                                        onClick={() => {
                                                            setHistoryId(externalId);
                                                            openHistory(true);
                                                        }}
                                                    >
                                                        <Restore />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                    );
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Meal Time',
                            field: `value.${MeasurementType.BloodGlucose}.mealTime`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                                patient,
                                timezoneName,
                            }) => {
                                if (glucoseValue) {
                                    const { mealTime } = glucoseValue;
                                    if (mealTime) {
                                        return (
                                            <Typography>
                                                {displayDateWithAbbrTimeZone({
                                                    isoDateStr: mealTime,
                                                    format: 'hh:mm A',
                                                    timeZone:
                                                        timezoneName ??
                                                        patient?.practice.timezone ??
                                                        Intl.DateTimeFormat().resolvedOptions()
                                                            .timeZone,
                                                })}
                                            </Typography>
                                        );
                                    }
                                    return <div />;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Meal Notes',
                            field: `value.${MeasurementType.BloodGlucose}.meal`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                            }) => {
                                if (glucoseValue) {
                                    const { meal } = glucoseValue;
                                    return <Typography>{meal}</Typography>;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Other Notes',
                            field: `value.${MeasurementType.BloodGlucose}.notes`,
                            render: ({
                                value: { [MeasurementType.BloodGlucose]: glucoseValue },
                            }) => {
                                if (glucoseValue) {
                                    const { notes } = glucoseValue;
                                    return <Typography>{notes}</Typography>;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Flagged',
                            field: 'flagged',
                            render: ({ flagged }) => {
                                if (flagged) {
                                    return <Warning />;
                                }
                                return <div />;
                            },
                            customSort: (a, b) => Number(a.flagged) - Number(b.flagged),
                        },
                    ]}
                    data={
                        filterByMealOption(
                            measurements && measurements.patient?.measurements
                                ? _.cloneDeep(measurements.patient.measurements)
                                : tableData,
                            MealOption.postDinner,
                        ) ?? []
                    }
                    options={materialTableOptions}
                />
            </Grid>
        </Grid>
    );
};
