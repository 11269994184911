import { RelationshipToInsuredEnum, VirtualCareSurveyInput } from '~/views/VirtualCareSurvey/types';
import { DestinationNames } from '~/schemaTypes';
import moment from 'moment';

export const dateToString = (date: Date) => date.toISOString().split('T')[0];

export const DATE_FORMAT = 'MM/DD/YYYY';

export const preparePayload = (
    data: VirtualCareSurveyInput,
    insuredAddressSameAsPatient: boolean,
    isAffiliateView?: boolean,
    affiliate?: { id?: string; selfCheck: boolean; eligibilityRequestId?: string | null },
) => {
    const isSelfInsured = data.patientRelationshipToInsured === RelationshipToInsuredEnum.SELF;
    if (isSelfInsured) {
        data.insuredFirstName = data.firstName;
        data.insuredLastName = data.lastName;
        data.subscriberSex = data.sex;
        data.subscriberBirthDate = data.birthDate;
    }
    if (insuredAddressSameAsPatient) {
        data.insuredAddressLine1 = data.addressLine1;
        data.insuredAddressLine2 = data.addressLine2;
        data.insuredCity = data.city;
        data.insuredState = data.state;
        data.insuredZipCode = data.zipCode;
    }
    return {
        firstName: data.firstName?.trim(),
        lastName: data.lastName?.trim(),
        birthDate:
            data.birthDate instanceof Date
                ? data.birthDate.toLocaleDateString()
                : moment(data.birthDate, DATE_FORMAT).toISOString(),
        address: {
            street1: data.addressLine1?.trim(),
            street2: data.addressLine2?.trim(),
            city: data.city?.trim(),
            state: data.state?.trim(),
            code: data.zipCode?.trim(),
        },
        phoneNumber: data.phoneNumber?.trim(),
        email: data.email?.trim(),
        sex: data.sex,
        insuranceId: data.insuranceId?.trim(),
        insuranceGroupNumber: data.insuranceGroup?.trim(),
        insuredFirstName: data.insuredFirstName?.trim(),
        insuredLastName: data.insuredLastName?.trim(),
        insuredAddress: {
            street1: data.insuredAddressLine1?.trim(),
            street2: data.insuredAddressLine2?.trim(),
            city: data.insuredCity?.trim(),
            state: data.insuredState?.trim(),
            code: data.insuredZipCode?.trim(),
        },
        insuredPhoneNumber: data.phoneNumber?.trim(),
        patientRelationshipToInsured: data.patientRelationshipToInsured,
        subscriberSex: data.subscriberSex,
        subscriberBirthDate:
            data.subscriberBirthDate instanceof Date
                ? data.subscriberBirthDate.toLocaleDateString()
                : moment(data.subscriberBirthDate, DATE_FORMAT).toISOString(),
        eligibilityRequestId: affiliate?.eligibilityRequestId,
        ...(data.appointmentMethod && { appointmentMethod: data.appointmentMethod }),
        ...(isAffiliateView && { Destination: DestinationNames.Affiliate }),
        ...(isAffiliateView &&
            affiliate && {
                affiliateId: affiliate.id,
                selfCheck: affiliate.selfCheck,
            }),
        babyMultiples: data.babyMultiples,
        babyArrived: data.babyArrived,
        babySex: data.babySex,
        babyFirstName: data.babyFirstName,
        babyLastName: data.babyLastName,
        babyBirthDate:
            data.babyBirthDate instanceof Date
                ? data.babyBirthDate.toLocaleDateString()
                : moment(data.babyBirthDate, DATE_FORMAT).toISOString(),
        babyDueDate:
            data.babyDueDate instanceof Date
                ? data.babyDueDate.toLocaleDateString()
                : moment(data.babyDueDate, DATE_FORMAT).toISOString(),
    };
};

export const generateBaseUrl = (
    isAffiliateView: boolean,
    affiliateId?: string,
    baseUrl?: string,
): string => {
    let url = isAffiliateView ? '/affiliate-care' : '/virtual-care';
    if (baseUrl) {
        url = baseUrl;
    }
    if (isAffiliateView && affiliateId) {
        url += `/${affiliateId}`;
    }
    return url;
};
