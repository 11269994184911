import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Select,
    MenuItem,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SaveIcon from '@mui/icons-material/Save';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { CIGNA_LACTATION_ORG_ID } from '~/constants';
import {
    useFetchStaffByIdForStaffModalLazyQuery,
    useUpdateStaffInStaffModalMutation,
    useStaffVirtualCareAffiliatessV2Query,
    OrderByDirectionEnum,
    FetchUsersForStaffPageQueryQuery,
    FetchUsersForStaffPageQueryDocument,
    useCreateAffiliateUserInAffiliateUsersModalMutation,
} from '~/schemaTypes';

const STAFF_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    affiliateId: Yup.string().required(),
});

const useStyles = makeStyles()({
    root: {},
});

interface AffiliateUserFormInput {
    name: string;
    email: string;
    affiliateId: string;
}

type AffiliateUserModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditStaffId: Dispatch<SetStateAction<string>>;
    id?: string;
};

const VCAffiliateUserModal: React.FC<AffiliateUserModalProps> = ({
    setOpen,
    setEditStaffId,
    id,
}) => {
    const { classes } = useStyles();
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<AffiliateUserFormInput>({
        resolver: yupResolver(STAFF_VALIDATION_SCHEMA as any),
    });

    const { data: affiliates } = useStaffVirtualCareAffiliatessV2Query({
        variables: {
            input: {
                orderBy: {
                    field: 'externalName',
                    order: OrderByDirectionEnum.Asc,
                },
            },
        },
    });

    const [getStaff, { data: staffQuery, loading }] = useFetchStaffByIdForStaffModalLazyQuery({
        onCompleted: data => {
            if (data.user) {
                reset({
                    name: data.user.name,
                    email: data.user.email,
                    affiliateId: data.user.affiliateId,
                });
            }
        },
        fetchPolicy: 'network-only',
    });

    const affiliateOptions = useMemo(() => {
        const initialOption = {
            title: 'Select Affiliate',
            value: '',
        };
        if (affiliates?.virtualCareAffiliatessV2.results) {
            return [
                initialOption,
                ...affiliates.virtualCareAffiliatessV2.results.map(affiliate => ({
                    title: affiliate.externalName,
                    value: affiliate.id,
                })),
            ];
        }
        return [initialOption];
    }, [affiliates]);

    const [updateUser, { loading: updateUserLoading }] = useUpdateStaffInStaffModalMutation();

    const [createUser, { loading: createUserLoading }] =
        useCreateAffiliateUserInAffiliateUsersModalMutation({
            update: (cache, response) => {
                if (response.data?.createAffiliateUser?.success) {
                    const newUser = response.data?.createAffiliateUser?.user;
                    if (newUser) {
                        const currentData = cache.readQuery<FetchUsersForStaffPageQueryQuery>({
                            query: FetchUsersForStaffPageQueryDocument,
                            variables: { input: { orgId: CIGNA_LACTATION_ORG_ID } },
                        });
                        if (currentData?.getUsersByOrg) {
                            cache.writeQuery<FetchUsersForStaffPageQueryQuery>({
                                query: FetchUsersForStaffPageQueryDocument,
                                data: {
                                    getUsersByOrg: [newUser, ...currentData.getUsersByOrg],
                                },
                                variables: { input: { orgId: CIGNA_LACTATION_ORG_ID } },
                            });
                        }
                    }
                }
            },
        });

    const onSubmit = ({ email, name, affiliateId }: AffiliateUserFormInput) => {
        if (id) {
            updateUser({
                variables: {
                    input: {
                        id,
                        data: {
                            name,
                            email,
                            affiliateId,
                        },
                    },
                },
            });
        } else {
            createUser({
                variables: {
                    input: {
                        name,
                        email,
                        affiliateId,
                    },
                },
            });
        }
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
        setEditStaffId('');
    };
    useEffect(() => {
        if (id) {
            getStaff({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getStaff]);

    if (loading || updateUserLoading || createUserLoading) {
        return <Loading />;
    }

    return (
        <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                {staffQuery?.user?.id === undefined ? 'New Affiliate User' : 'Edit Affiliate User'}
            </DialogTitleWithClose>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.name}
                            {...register('name')}
                            label="Name"
                            id="name"
                            type="text"
                            margin="dense"
                            fullWidth
                            helperText={errors.name?.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Email"
                            id="email"
                            {...register('email')}
                            type="text"
                            margin="dense"
                            fullWidth
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedSection title="Affiliate">
                            <Controller
                                name="affiliateId"
                                control={control}
                                render={({ field: { onChange, value, ...props } }) => (
                                    <Select
                                        id="affiliateId"
                                        onChange={e => onChange(e)}
                                        error={!!errors.affiliateId}
                                        value={value ?? ''}
                                        fullWidth
                                        displayEmpty
                                        {...props}
                                    >
                                        {affiliateOptions.map(affiliate => (
                                            <MenuItem key={affiliate.value} value={affiliate.value}>
                                                {affiliate.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </OutlinedSection>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default VCAffiliateUserModal;
