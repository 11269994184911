import { ContentType, DirectoryDisplayStyle, Language, SimpleContent } from '~/schemaTypes';

const simpleContentToLink = ({
    content,
    displayStyle,
    overrideLabel,
    language = Language.En,
    careTeamMemberTypes,
}: {
    content: SimpleContent;
    displayStyle?: DirectoryDisplayStyle;
    overrideLabel?: string;
    language?: string;
    careTeamMemberTypes: any;
}) => {
    const label = content.label || { en: content.name, es: content.name };
    let text = label.en;
    if (language === Language.Es) text = label.es;
    if (overrideLabel) text = overrideLabel;
    switch (content.contentType) {
        case ContentType.ExploreScreen:
            return `<a href="app:#/exploreScreen" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.Resources:
            return `<a href="app:#/resources" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.TrackerView:
            return `<a href="app:#/trackersView" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.Highlights:
            return `<a href="app:#/whatsNew" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.Todolist:
            return `<a href="app:#/toDoMain" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.BabyBoostLink:
            return `<a href="app:#/babyBoost" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.CareTeam:
            return `<a href="app:#/careTeam" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.MessageCenter:
            if (content.careTeamMemberTypeId) {
                const careTeamType = careTeamMemberTypes.find(
                    (t: { id: any }) => t.id === content.careTeamMemberTypeId,
                );
                if (careTeamType) {
                    return `<a href="app:#/messageCenterChatView/${careTeamType.profileDefId}" data-id="${content.id}" title="${text}">${text}</a>`;
                }
            }
            return `<a href="app:#/messageCenterChatView" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.QListLink:
            return `<a href="app:#/qList" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.DueDateCalculator:
            return `<a href="app:#/dueDateCalculatorNew" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.ExternalLink:
            return `<a href="${content.content}" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.ArticleLink:
            return `<a href="app:/article" data-content-type="internal-link" data-content-id="${content.articleId}" data-id="${content.id}" title="${text}" class="content-link">${text}</a>`;
        case ContentType.PhoneNumber:
            return `<a href="app:/phone" data-content-type="internal-link" data-content-id="${content.content}" data-id="${content.id}" title="${text}" class="content-link">${text}</a>`;
        case ContentType.Tracker:
            return `<a href="app:/tracker" data-content-type="internal-link" data-content-id="${content.trackerType}" data-id="${content.id}" title="${text}" class="content-link">${text}</a>`;
        case ContentType.SurveyLink:
            return `<a href="app:/survey" data-content-type="internal-link" data-content-id="${content.surveyId}" data-id="${content.id}" title="${text}" class="content-link">${text}</a>`;
        case ContentType.ContentDirectoryLink:
            if (displayStyle) {
                return `<a href="app:/directory" data-content-type="internal-link" data-content-displayStyle="${displayStyle}" data-content-id="${content.contentDirectoryId}" data-id="${content.id}" title="${text}" class="content-link">${text}</a>`;
            }
            break;
        case ContentType.AddDependentProfile:
            return `<a href="app:#/addDependentProfile" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.UserBio:
            return `<a href="app:/userBio" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.LearnLibrary:
            return `<a href="app:#/learn-library" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.Milestones:
            return `<a href="app:#/milestones" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.Surveys:
            return `<a href="app:#/intakeFormList" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.WeekByWeek:
            return `<a href="app:#/week-by-week" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.WhoToCall:
            return `<a href="app:#/who-to-call" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.AppointmentHistory:
            return `<a href="app:#/appointmentHistoryView" data-id="${content.id}" title="${text}">${text}</a>`;
        case ContentType.EligibilityCheckLink:
            return `<a href="app:#/eligibilityCheckView" title="${text}">${text}</a>`;
        default:
            return '';
    }
    return '';
};

export default simpleContentToLink;
