export type SelectedPatient = {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    birthDate: string;
    email?: string | null;
    multiples?: boolean | null;
};

export type Claim = {
    firstName: string;
    lastName: string;
    birthDate: string;
    dateOfService: Date;
    serviceType: string;
    id: number;
    patientId: string;
};

export enum ServiceTypes {
    Virtual = 'Virtual',
    Office = 'Office',
    Home = 'Home',
}
