import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';
import { TriggerGlobalAlert } from '~/state';
import { AlertSeverity, useUploadClaimFileMutation } from '~/schemaTypes';
import { useForm } from 'react-hook-form';
import { useStyles } from '~/views/AffiliateClaimSubmission/styles';
import { TabsId } from '~/views/AffiliateCare/Claims/Claims';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import { FILE_TYPE_TO_NAME_MAP } from './constants';
import { generateInvalidFileFormatMessage } from './helpers';
import PortalUserView from './components/PortalUserView';
import AffiliateCareView from './components/AffiliateCareView';
import { ProcessingError } from './types';
import ErrorDialog from './components/ErrorDialog';

export const AffiliateClaimSubmission = () => {
    const { userAffiliateId, isAffiliateView } = useAffiliateCareView();
    const { search } = useLocation();
    const history = useNavigate();
    const searchParam = useMemo(() => new URLSearchParams(search), [search]);
    const aid = searchParam.get('aid');
    const { classes } = useStyles();
    const { register, reset } = useForm();

    const fileRef = useRef<{ file: string[]; name: string } | null>(null);
    const claimRef = useRef<string | null>(null);

    const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
    const handleCloseModal = useCallback(() => {
        setIsProcessingModalOpen(false);
    }, [setIsProcessingModalOpen]);
    const [processingError, setProcessingError] = useState<ProcessingError>();

    const handleClearForm = useCallback(() => {
        reset();
    }, [reset]);

    useEffect(() => {
        if (userAffiliateId) {
            history(`/affiliate-care-portal/claims/submit?aid=${userAffiliateId}`, {
                replace: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAffiliateId]);

    const [submitClaimTemplate, { loading: isProcessing }] = useUploadClaimFileMutation({
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
        onCompleted: data => {
            if (data.createAffiliateClaim?.error) {
                setIsProcessingModalOpen(true);
                setProcessingError({
                    message: data.createAffiliateClaim?.error.message,
                    data: data.createAffiliateClaim?.error.data,
                });
            } else {
                claimRef.current = data.createAffiliateClaim?.claim?.id ?? null;
                setProcessingError(null);
                handleCloseModal();
                history('/affiliate-care-portal/claims', { state: { initTab: TabsId.Batch } });
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: data.createAffiliateClaim?.message ?? 'Claim was created',
                });
            }
            handleClearForm();
        },
    });

    const submitClaimTemplateFile = useCallback(
        (data: { name: string; file: File }) => {
            if (!aid) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Affiliate ID is missing',
                });
                return;
            }
            if (data.file) {
                if (FILE_TYPE_TO_NAME_MAP.has(data.file.type)) {
                    const reader = new FileReader();
                    reader.onload = async () => {
                        if (reader.result) {
                            const buffer = reader.result as unknown as string[];
                            const fileName = `${Date.now()}_${data.name}`;
                            fileRef.current = { file: buffer, name: fileName };
                            try {
                                await submitClaimTemplate({
                                    variables: {
                                        input: {
                                            file: buffer,
                                            affiliateId: aid,
                                            fileName,
                                            dateTime: new Date().toISOString(),
                                        },
                                    },
                                });
                            } catch (err: any) {
                                TriggerGlobalAlert({
                                    severity: AlertSeverity.Error,
                                    message: JSON.stringify(err?.message),
                                });
                            }
                        }
                    };
                    reader.readAsText(data.file, 'UTF-8');
                } else if (FILE_TYPE_TO_NAME_MAP.size) {
                    const acceptedFormats = Array.from(FILE_TYPE_TO_NAME_MAP.values());
                    const message = generateInvalidFileFormatMessage(acceptedFormats);
                    setIsProcessingModalOpen(true);
                    setProcessingError({ message });
                }
            }
        },
        [aid, submitClaimTemplate],
    );

    const handleFileChange = useCallback(
        ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
            if (files && files.length) {
                const file = files[0];
                submitClaimTemplateFile({ name: file.name, file });
            }
        },
        [submitClaimTemplateFile],
    );

    return (
        <Container maxWidth="md" className={classes.container}>
            <img src="/logo-with-title.png" alt="logo" className={classes.logo} />

            <Typography className={classes.headingTitle} variant="h6">
                Batch Claim Submission
            </Typography>
            {isAffiliateView ? (
                <AffiliateCareView
                    handleClearForm={handleClearForm}
                    isProcessing={isProcessing}
                    register={register('affiliateClaimTemplate')}
                    handleFileChange={handleFileChange}
                />
            ) : (
                <PortalUserView
                    register={register('affiliateClaimTemplate')}
                    isProcessing={isProcessing}
                    handleClearForm={handleClearForm}
                    handleFileChange={handleFileChange}
                />
            )}
            <Box className={classes.contactBlock}>
                <Typography variant="body1">
                    Need support with your claims submission? Email{' '}
                    <Link
                        rel="noopener noreferrer"
                        href="mailto:Billing@wildflowertelehealth.com"
                        underline="hover"
                    >
                        Billing@wildflowertelehealth.com
                    </Link>
                </Typography>
            </Box>
            <ErrorDialog
                open={isProcessingModalOpen}
                onClose={handleCloseModal}
                processingError={processingError}
            />
        </Container>
    );
};
