import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, TablePagination } from '@mui/material';
import { Add } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    CareTeamContact,
    CareTeamContactFilterInput,
    FetchCareTeamContactsDocument,
    FetchCareTeamContactsQueryVariables,
    useCreateCareTeamContactMutation,
    useDeleteCareTeamContactMutation,
    useFetchCareTeamContactsQuery,
    useUpdateCareTeamContactMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import CareTeamContactModal from './CareTeamContactModal/CareTeamContactModal';
import useStyles from './styles';

const CareTeamContacts: React.FC = () => {
    const { classes } = useStyles();

    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);

    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [page, setPage] = useState<number>(0);
    const [showCareTeamContactModal, setShowCareTeamContactModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [careTeamContactId, setCareTeamContactId] = useState('');
    const [filter, setFilter] = useState<IFilterChange<CareTeamContact> | null>(null);

    const fetchExpressionsForPatientsPageQueryVariables: FetchCareTeamContactsQueryVariables = {
        input: {
            pagination: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            },
            filter: filter as CareTeamContactFilterInput | null,
        },
    };

    const [createCareTeamContact, { loading: createCareTeamContactLoading }] =
        useCreateCareTeamContactMutation({
            onCompleted: data => {
                if (data.createCareTeamContact?.success) {
                    setShowCareTeamContactModal(false);
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FetchCareTeamContactsDocument,
                    variables: fetchExpressionsForPatientsPageQueryVariables,
                },
            ],
        });

    const [deleteCareTeamContact, { loading: isCareTeamContactDeleteLoading }] =
        useDeleteCareTeamContactMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FetchCareTeamContactsDocument,
                    variables: fetchExpressionsForPatientsPageQueryVariables,
                },
            ],
        });

    const [updateCareTeamContact, { loading: updateCareTeamContactLoading }] =
        useUpdateCareTeamContactMutation({
            onCompleted: data => {
                if (data.updateCareTeamContact?.success) {
                    setShowCareTeamContactModal(false);
                    setCareTeamContactId('');
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FetchCareTeamContactsDocument,
                    variables: fetchExpressionsForPatientsPageQueryVariables,
                },
            ],
        });

    const { data: careTeamContactsData, loading: isCareTeamContactsDataLoading } =
        useFetchCareTeamContactsQuery({
            variables: fetchExpressionsForPatientsPageQueryVariables,
        });

    const handleAddCareTeamContact = () => {
        setShowCareTeamContactModal(true);
        setIsEditMode(false);
    };

    const handleEditCareTeamContact = (event: any, element: any) => {
        setShowCareTeamContactModal(true);
        setCareTeamContactId(element.id);
        setIsEditMode(true);
    };

    const handleDeleteCareTeamContact = (id: string) => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Care Team Contact',
            callback: () => {
                deleteCareTeamContact({ variables: { input: { id } } });
            },
        });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Care Team Contacts"
                isLoading={isCareTeamContactsDataLoading || isCareTeamContactDeleteLoading}
                icons={tableIcons}
                data={careTeamContactsData?.careTeamContactsV2.results ?? []}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={careTeamContactsData?.careTeamContactsV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        onClick: event => {
                            event.preventDefault();
                        },
                        hidden: !pagePermissions?.Expressions.Edit,
                        icon: () => (
                            <CustomFilter<CareTeamContact>
                                setFilter={setFilter}
                                defaultField="name"
                                fields={[
                                    'name',
                                    'phoneNumber',
                                    'emailAddress',
                                    'careTeamMemberTypeId',
                                ]}
                            />
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: () => handleAddCareTeamContact(),
                        icon: () => <Add />,
                        tooltip: 'Add Care Team Contact',
                        isFreeAction: true,
                        hidden: !pagePermissions?.CareTeamContacts.Edit,
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        onClick: (event, element) => handleEditCareTeamContact(event, element),
                        tooltip: 'Edit Care Team Contact',
                        hidden: !pagePermissions?.CareTeamContacts.Edit,
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        onClick: (_, { id }: any) => handleDeleteCareTeamContact(id),
                        tooltip: 'Delete Care Team Contact',
                        hidden: !pagePermissions?.CareTeamContacts.Delete,
                    },
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                    },
                    {
                        title: 'Address',
                        field: 'address.street1',
                    },
                    {
                        title: 'Phone Number',
                        field: 'phoneNumber',
                    },
                    {
                        title: 'Email',
                        field: 'emailAddress',
                    },
                    {
                        title: 'Care Team Member Type',
                        field: 'careTeamMemberType.name',
                    },
                ]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100], search: false }}
            />
            <Dialog
                scroll="body"
                open={showCareTeamContactModal}
                fullWidth
                maxWidth="lg"
                aria-labelledby="form-dialog-title"
            >
                <CareTeamContactModal
                    id={careTeamContactId}
                    isEditMode={isEditMode}
                    setOpen={setShowCareTeamContactModal}
                    createCareTeamContact={createCareTeamContact}
                    updateCareTeamContact={updateCareTeamContact}
                    isCreateCareTeamContactLoading={updateCareTeamContactLoading}
                    isUpdateCareTeamContactLoading={createCareTeamContactLoading}
                />
            </Dialog>
        </div>
    );
};

export default CareTeamContacts;
