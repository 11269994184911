import { Link, Tab, Tabs, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { lowercaseFirstLetter } from '~/helpers';
import { flipDirection } from '~/helpers/flipDirection';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AdvocateTasksForPatientHaTaskPageQuery,
    AdvocateTaskTypeStatus,
    OrderByDirectionEnum,
    Target,
    ReferencePages,
    useAdvocateTasksForPatientHaTaskPageQuery,
    useFetchAdvocatesForHaTaskPatientPageQuery,
    useFetchReferenceLinksForPatientHaTaskPageQuery,
} from '~/schemaTypes';
import {
    AdvocateTaskListSortingFields,
    IAdvocateTaskListOrderChange,
} from '~/views/Dashboard/AdvocateTaskList/shared/types';

type AdvocateTask = NonNullable<
    AdvocateTasksForPatientHaTaskPageQuery['customFilteredAdvocateTasks']
>['results'][0];

type PatientHATasksProps = {
    setLinks?: any;
};

// corresponds to columns={[]} passed to MaterialUI
const columnIdToSortingFieldMap: Record<number, AdvocateTaskListSortingFields> = {
    2: AdvocateTaskListSortingFields.dueDate,
    3: AdvocateTaskListSortingFields.completedAt,
    4: AdvocateTaskListSortingFields.createdAt,
    5: AdvocateTaskListSortingFields.priority,
    6: AdvocateTaskListSortingFields.assignedTo,
};

export const PatientHATasks: React.FC<PatientHATasksProps> = ({ setLinks }) => {
    const history = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { pagePermissions } = useUserPermissions();
    const [status, setStatus] = useState<AdvocateTaskTypeStatus>(AdvocateTaskTypeStatus.Open);
    const [order, setOrder] = useState<IAdvocateTaskListOrderChange>({
        field: AdvocateTaskListSortingFields.dueDate,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
    });

    const { data: advocates } = useFetchAdvocatesForHaTaskPatientPageQuery({
        variables: {
            permission: lowercaseFirstLetter(Target.IsHealthAdvocate),
        },
    });

    const { data: patientHATasks, loading } = useAdvocateTasksForPatientHaTaskPageQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        status,
                    },
                },
                customFilters: {
                    patients: [id ?? ''],
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
            },
        },
    });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForPatientHaTaskPageQuery({
            variables: {
                input: {
                    page: ReferencePages.PatientHaTasks,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const changeDirection = (previousDirection: OrderByDirectionEnum): void => {
        setOrder(state => ({
            ...state,
            direction: flipDirection(previousDirection),
            isInit: false,
        }));
    };

    const handleOrderChange = (columnId: number, orderDirection: string) => {
        if (columnIdToSortingFieldMap[columnId] !== order.field) {
            setOrder(state => ({
                ...state,
                isInit: true,
            }));
        }
        const direction = order.isInit ? getDirection(orderDirection) : order.direction;
        changeDirection(direction);
        setOrder(state => ({
            ...state,
            field: columnIdToSortingFieldMap[columnId],
        }));
    };

    const getHeaderStyle = (field: AdvocateTaskListSortingFields): React.CSSProperties => ({
        fontWeight: order.field === field ? 'bold' : 'initial',
    });

    if (loading) {
        return <Loading subtitle="Loading HA Tasks" />;
    }

    return (
        <>
            <Tabs value={status} onChange={(_, value) => setStatus(value)}>
                <Tab label="Open" value={AdvocateTaskTypeStatus.Open} />
                <Tab label="Closed" value={AdvocateTaskTypeStatus.Closed} />
            </Tabs>
            <MaterialTable<AdvocateTask>
                totalCount={patientHATasks?.customFilteredAdvocateTasks.total}
                onOrderChange={handleOrderChange}
                isLoading={loading || refereneLinksLoading}
                icons={tableIcons}
                title="Tasks"
                data={_.cloneDeep(patientHATasks?.customFilteredAdvocateTasks.results) ?? []}
                actions={[
                    {
                        onClick: () => history(`/portal/advocate-tasks/new?patientId=${id}`),
                        hidden: !pagePermissions?.AdvocateTaskList.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Advocate Task',
                        isFreeAction: true,
                    },
                ]}
                columns={[
                    {
                        title: 'Title',
                        field: 'label',
                        render: ({ id, label }) => {
                            return (
                                <Link
                                    to={`/portal/advocate-tasks/${id}`}
                                    component={RouterLink}
                                    underline="hover"
                                >
                                    {label}
                                </Link>
                            );
                        },
                        sorting: false,
                    },
                    {
                        title: 'Complete By',
                        field: 'dueDate',
                        render: ({ dueDate }) => {
                            return (
                                <>
                                    {moment(dueDate).format('MM/DD/YYYY')}
                                    {moment(new Date()).isAfter(moment(dueDate)) && (
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                                            {' !'}
                                        </span>
                                    )}
                                </>
                            );
                        },
                        headerStyle: getHeaderStyle(AdvocateTaskListSortingFields.dueDate),
                        searchable: false,
                        hidden: status === AdvocateTaskTypeStatus.Closed,
                    },
                    {
                        title: 'Closed On',
                        field: 'completedAt',
                        render: ({ completedAt }) => {
                            return moment(completedAt).format('MM/DD/YYYY');
                        },
                        headerStyle: getHeaderStyle(AdvocateTaskListSortingFields.completedAt),
                        searchable: false,
                        hidden: status === AdvocateTaskTypeStatus.Open,
                    },
                    {
                        title: 'Created On',
                        field: 'createdAt',
                        render: ({ createdAt }) => {
                            return moment(createdAt).format('MM/DD/YYYY');
                        },
                        headerStyle: getHeaderStyle(AdvocateTaskListSortingFields.createdAt),
                        searchable: false,
                    },
                    {
                        title: 'Priority',
                        field: 'priority',
                        headerStyle: getHeaderStyle(AdvocateTaskListSortingFields.priority),
                        searchable: false,
                    },
                    {
                        title: 'Assigned To',
                        field: 'assignedTo',
                        render: ({ assignedTo }) => {
                            return (
                                <Typography>
                                    {advocates?.getUsersByPermission?.find(
                                        a => assignedTo === a?.id,
                                    )?.name ?? 'Unassigned'}
                                </Typography>
                            );
                        },
                        searchable: false,
                    },
                ]}
            />
        </>
    );
};
