import * as Yup from 'yup';
import { ClientSelectionType } from '~/enums/enums';

export const MESSAGE_CENTER_TEMPLATE_SCHEMA = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    content: Yup.object().shape({
        en: Yup.string().required(),
        es: Yup.string().nullable(),
    }),
    includeApps: Yup.array().of(Yup.string().required()).required(),
    excludeApps: Yup.array().of(Yup.string().required()).required(),
    clientSelectionType: Yup.string().oneOf(Object.values(ClientSelectionType)).required(),
});
