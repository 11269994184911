import MomentUtils from '@date-io/moment';
import { formatISO } from 'date-fns';
import moment from 'moment';

export const isToday = (date: Date): boolean => {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const buildMomentUtils = (timeZone: string): any => {
    moment.tz.setDefault(timeZone);
    return MomentUtils;
};

export const displayDate = ({
    isoDateStr,
    format = 'MM/DD/YYYY',
    timeZone = 'UTC',
    displayTimeZone = false,
}: {
    isoDateStr: string;
    format?: string;
    timeZone?: string;
    displayTimeZone?: boolean;
}): string => {
    return `${moment.utc(isoDateStr).tz(timeZone).format(format)}${
        displayTimeZone ? ` ${timeZone}` : ''
    }`;
};

export const displayDateLocale = ({
    isoDateStr,
    format = 'MM/DD/YYYY',
}: {
    isoDateStr: string;
    format?: string;
}): string => {
    return moment.utc(isoDateStr).local().format(format);
};

export const displayBirthday = ({
    isoDateStr,
    format = 'MM/DD/YYYY',
}: {
    isoDateStr: string;
    format?: string;
}): string => {
    return displayDate({ isoDateStr, format, timeZone: 'UTC' });
};

export const displayDateWithAbbrTimeZone = ({
    isoDateStr,
    format = 'MM/DD/YYYY',
    timeZone = 'UTC',
}: {
    isoDateStr: string;
    format?: string;
    timeZone?: string;
}): string => {
    return `${moment.utc(isoDateStr).tz(timeZone).format(format)} ${moment()
        .tz(timeZone)
        .zoneAbbr()} `;
};

export const dateToISOString = (date: string | Date) => {
    return `${formatISO(new Date(date), { representation: 'date' })}T00:00:00+00:00`;
};
