import { Typography, IconButton, Tooltip } from '@mui/material';
import { Warning, Restore } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import tableIcons from '~/helpers/tableIcons';
import { useQueryParams } from '~/hooks';
import useUserPermissions from '~/hooks/useUserPermissions';
import { weightValueLabel } from '~/utils/parseKgToLbsForMeasurements';
import { WeightPropsInterface } from '../interfaces';
import { useStyles } from '../styles';

export const WeightMeasurementTable: React.FC<WeightPropsInterface> = ({
    data,
    isLoading,
    openHistory,
    setHistoryId,
}) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [query] = useQueryParams();
    const [search, setSearch] = useState<string>(query.get('_search') ?? '');
    const history = useNavigate();
    const location = useLocation();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    return (
        <div ref={tableRef}>
            <MaterialTable
                title="Measurements"
                isLoading={isLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                columns={[
                    {
                        title: 'Date of Test',
                        render: ({ takenDate, patient, timezoneName }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: takenDate,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone:
                                        timezoneName ??
                                        patient?.practice.timezone ??
                                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.takenDate).getTime() - new Date(b.takenDate).getTime(),
                        defaultSort: 'desc',
                    },
                    {
                        title: 'Date of Report',
                        render: ({ updatedAt }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: updatedAt,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
                        defaultSort: 'desc',
                    },
                    {
                        title: 'Entry',
                        render: ({ value, externalId, hasHistory }) => {
                            if (value?.weight?.measure) {
                                return (
                                    <div className={classes.horizontalAlign}>
                                        <Typography>
                                            {weightValueLabel(
                                                value.weight.measure,
                                                value.weight.units ?? null,
                                            )}
                                        </Typography>
                                        {hasHistory && (
                                            <Tooltip title="History">
                                                <IconButton
                                                    onClick={() => {
                                                        setHistoryId(externalId);
                                                        openHistory(true);
                                                    }}
                                                >
                                                    <Restore />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </div>
                                );
                            }
                            return <div />;
                        },
                    },
                    {
                        title: 'Flagged',
                        render: ({ flagged }) => {
                            if (flagged) {
                                return <Warning />;
                            }
                            return <div />;
                        },
                        customSort: (a, b) => Number(a.flagged) - Number(b.flagged),
                    },
                ]}
                data={_.cloneDeep(data) ?? []}
                onSearchChange={searchText => {
                    setSearch(searchText);
                    history(`${location.pathname}${searchText && `?_search=${searchText}`}`);
                }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    searchText: search ?? '',
                    exportAllData: pagePermissions?.Measurements.Export,
                    grouping: true,
                }}
            />
        </div>
    );
};
