import { Grid, MenuItem, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import useStyles from './styles';

export interface IFilterChange<T> {
    fields: Partial<Record<keyof T, string>>;
}

interface ICustomFilterProps<T> {
    setFilter: Dispatch<SetStateAction<IFilterChange<T> | null>>;
    fields: string[];
    fieldNames?: Record<string, unknown>;
    defaultField?: keyof T;
}

interface IFilterField<T> {
    searchText: string;
    field: keyof T;
}

function CustomFilter<T>({
    setFilter,
    fields,
    fieldNames,
    defaultField,
}: ICustomFilterProps<T>): JSX.Element {
    const { classes } = useStyles();
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<IFilterField<T>>();
    const [isShrunk, setIsShrunk] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [currentFilterField, setCurrentFilterField] = useState<keyof T | ''>(defaultField ?? '');
    const [currentFilterSearchText, setCurrentFilterSearchText] = useState<string>('');

    const onSubmit = ({ field, searchText }: IFilterField<T>) => {
        const trimmedSearchText = searchText.trim();
        setCurrentFilterField(field);
        setCurrentFilterSearchText(trimmedSearchText);
        const fields = {
            [field]: trimmedSearchText,
        } as Record<keyof T, string>;

        if (fields) {
            setFilter({ fields });
        }
    };

    const handleReset = () => {
        setFilter(null);
        setCurrentFilterField('');
        setCurrentFilterSearchText('');
        setIsShrunk(false);
    };

    const backButtonClassNames = [
        `${classes.iconButton}`,
        `${String(currentFilterField).length ? '' : classes.hide}`,
    ].join(' ');
    return (
        <form onReset={handleReset} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="row">
                <Grid item xs={6} className={classes.dense}>
                    <ReactHookFormSelect
                        control={control}
                        name="field"
                        variant="outlined"
                        label="Search Field"
                        fullWidth
                        margin="dense"
                        defaultValue={String(currentFilterField)}
                        error={!!errors.field}
                        className={classes.searchField}
                    >
                        {fields.map(field => (
                            <MenuItem key={field} value={field}>
                                {`${fieldNames?.[field] ?? field}`}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                </Grid>
                <Grid item xs={String(currentFilterField).length ? 5 : 6} className={classes.dense}>
                    <TextField
                        variant="outlined"
                        label="Search Text"
                        fullWidth
                        margin="dense"
                        defaultValue={currentFilterSearchText}
                        spellCheck={false}
                        type="searchText"
                        {...register('searchText')}
                        onKeyUp={(event: any) => {
                            if (event.key === 'Enter') {
                                handleSubmit(onSubmit)();
                            }
                        }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={(
                            event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
                        ) => {
                            setIsShrunk(!!event.target.value);
                            setIsFocused(false);
                        }}
                        InputLabelProps={{
                            shrink: isFocused || isShrunk,
                        }}
                    />
                </Grid>
                <Grid item xs={1} className={backButtonClassNames}>
                    <FontAwesomeIcon
                        icon={faSearchMinus}
                        title="Reset Search"
                        onClick={() => reset()}
                    />
                </Grid>
                <div style={{ textAlign: 'right', width: '80%', fontSize: '.5em' }}>
                    Press Enter to search
                </div>
            </Grid>
        </form>
    );
}

export default CustomFilter;
